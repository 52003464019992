import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function ToolLink({ link }) {
  return (
    <Link
      to={link}
      className="bg-primary-btn-light dark:bg-primary-btn-dark hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark px-4 py-2 text-center text-white duration-200 rounded-lg">
      Try it!
    </Link>
  );
}

ToolLink.propTypes = {
  link: PropTypes.string.isRequired,
};
