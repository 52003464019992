export const initialState = {
  email: "",
  password: "",
  passwordError: "",
  emailError: "",
  loading: false,
};

export const loginReducer = (state, action) => {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload, emailError: "" };
    case "SET_PASSWORD":
      return { ...state, password: action.payload, passwordError: "" };
    case "SET_PASSWORD_ERROR":
      return { ...state, passwordError: action.payload };
    case "SET_EMAIL_ERROR":
      return { ...state, emailError: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "RESET_ERRORS":
      return { ...state, emailError: "", passwordError: "" };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};
