import { useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";

import { API_URL } from "../utils/api";

export default function ValidateEmail() {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${API_URL}/users/complete_register/${token}`,
          {
            method: "POST",
          }
        );

        if (!response.ok) {
          throw response;
        }

        const data = await response.json();
        sessionStorage.setItem("user", JSON.stringify(data.user_info));
        navigate("/confirm");
      } catch (error) {
        const data = await error.json();
        toast.dismiss();
        data?.detail
          ? toast.error(`${data.detail}`)
          : toast.error("An error occurred while validating email");
        navigate("/");
      }
    })();
  }, [token, navigate]);
}
