import MoleculeViewer from "../module4/MoleculeViewer";
import PageHeader from "../ui/PageHeader";
import useValidateProject from "../../hooks/useValidateProject";
import ContentLayout from "../../layouts/ContentLayout";
import ModuleSelectorButton from "../project_details/ui/ModuleSelectorButton";

export default function Module4() {
  const { id: projectId, loadingInformation } = useValidateProject();

  return (
    <>
      {!loadingInformation && (
        <PageHeader to={`/projects?project=${projectId}`} title="Module 4">
          <ModuleSelectorButton projectId={projectId} moduleId={4} />
        </PageHeader>
      )}
      <ContentLayout>
        <MoleculeViewer />
      </ContentLayout>
    </>
  );
}
