import PropTypes from "prop-types";

import { useState, useContext } from "react";

import axios from "axios";

import toast from "react-hot-toast";

import { GlobalContext } from "../../contexts/GlobalContext";
import { API_URL } from "../../utils/api";

import SearchButton from "./ui/SearchButton";
import SearchForm from "./ui/SearchForm";

export default function GeneSearch({ handleGeneResultsChange }) {
  const [gene, setGene] = useState({ value: "", error: "" });
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useContext(GlobalContext);
  const loadingInformation = state.loadingInformation;

  async function handleGeneSearch(e) {
    toast.dismiss();
    e.preventDefault();
    if (gene.value.trim() === "") {
      setGene({ ...gene, error: "Gene and organism must not be empty" });
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/genbank/search?query=${gene.value.split("(")[0].trim()}`
      );
      if (response.data?.length === 0) {
        toast.error("No results found have been found for this search");
      }
      handleGeneResultsChange({
        clickedIdentifier: "",
        results: response.data || [],
      });
    } catch (error) {
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error("An error occurred while searching for results");
    }
    setIsLoading(false);
  }

  return (
    <article className="space-y-4">
      <h4 className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
        Gene and Organism Search
      </h4>
      <p className="text-tertiary-light dark:text-tertiary-dark">
        In this module, the input is the information of the protein you wish to
        produce, along with information about the desired receptor microoganism.
      </p>
      <SearchForm
        labelValue={"Enter gene and organism"}
        identifier={"gene"}
        handleSubmit={handleGeneSearch}
        error={gene.error}>
        <input
          type="text"
          disabled={isLoading}
          name={"gene"}
          id={"gene"}
          className={`rounded-l-lg w-full p-2 bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border outline-none  placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark ${
            gene.error
              ? "border-red-500"
              : "border-light dark:border-dark dark:focus:!border-blue-400 focus:!border-blue-500"
          }`}
          placeholder={"Example: CSN3 Ovis Aries"}
          value={gene.value}
          onChange={({ target }) => setGene({ value: target.value, error: "" })}
        />
        <SearchButton
          isLoading={isLoading}
          disabled={isLoading || loadingInformation}
        />
      </SearchForm>
    </article>
  );
}

GeneSearch.propTypes = {
  handleGeneResultsChange: PropTypes.func.isRequired,
};
