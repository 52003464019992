import { useState } from "react";

import toast from "react-hot-toast";

import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import ToolsSelector from "../components/toolbox/ToolSelector";
import PageHeader from "../components/ui/PageHeader";

export default function SequenceCutter() {
  const [sequence, setSequence] = useState("");
  const [cutSequence, setCutSequence] = useState("");
  const [values, setValues] = useState({
    start: 0,
    end: 0,
  });

  function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();
    if (
      sequence.length > 1 &&
      values.start &&
      values.end &&
      values.start < values.end
    ) {
      setCutSequence(sequence.slice(values.start - 1, values.end));
    } else {
      toast.error("Please insert a valid sequence and range.");
    }
  }

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Sequence Cutter">
        <ToolsSelector id={1} />
      </PageHeader>
      <ContentLayout>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className=" space-y-4">
            <label
              htmlFor="fasta"
              className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              1. Insert your FASTA Sequence
            </label>
            <textarea
              value={sequence}
              onChange={(e) => {
                if (e.target.value.trim() !== "") {
                  const data = e.target.value.toUpperCase().split("\n");
                  const res = data.filter((t) => !t.match(/[^ATCG]/g));
                  if (res.length > 0) setSequence(res.join(""));
                } else if (e.target.value.trim() === "") setSequence("");
                else setSequence((prev) => prev);
                setValues({ start: 0, end: 0 });
                setCutSequence("");
              }}
              name="fasta"
              id="fasta"
              cols="30"
              rows="10"
              className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
              placeholder=">Sequence"
            />
            <span className="text-tertiary-light dark:text-tertiary-dark">{`* FASTA Sequence Length: ${sequence.length}`}</span>
          </div>
          <div className="w-full">
            <span className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              2. Cut from{" "}
            </span>
            <input
              disabled={!sequence || sequence.length < 2}
              type="number"
              step={1}
              min={1}
              max={sequence.length - 1}
              value={values.start || ""}
              onChange={(e) => {
                if (
                  parseInt(e.target.value) < 1 ||
                  isNaN(parseInt(e.target.value))
                ) {
                  setValues({ ...values, start: 0 });
                  return;
                }
                if (parseInt(e.target.value) < sequence.length) {
                  setValues({ start: parseInt(e.target.value), end: 0 });
                }
                setCutSequence("");
              }}
              className="w-32 p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
              placeholder="Start"
            />
            <span className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
              {" "}
              to{" "}
            </span>
            <input
              disabled={!values.start || !sequence}
              type="number"
              step={1}
              min={parseInt(values.start) + 1}
              max={sequence.length}
              className="w-32 p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
              placeholder="End"
              value={values.end || ""}
              onChange={(e) => {
                if (!e.target.value) {
                  setValues({ ...values, end: 0 });
                  return;
                }
                if (
                  parseInt(e.target.value) > sequence.length ||
                  isNaN(parseInt(e.target.value))
                ) {
                  setValues((prev) => ({ ...prev }));
                  return;
                }
                setValues({ ...values, end: parseInt(e.target.value) });
                setCutSequence("");
              }}
            />
          </div>
          <button className="bg-primary-btn-light dark:bg-primary-btn-dark text-white rounded-lg enabled:hover:bg-primary-btn-hover enabled:dark:hover:bg-primary-btn-hoverDark duration-200 w-full disabled:cursor-not-allowed disabled:bg-opacity-70 px-4 py-2 flex justify-center items-center">
            Cut Sequence
          </button>
        </form>

        {cutSequence && (
          <div className="w-full border p-4 rounded-xl shadow-lg shadow-light dark:shadow-dark space-y-4 border-light dark:border-dark">
            <div className="flex items-center justify-between space-x-2">
              <h3 className="text-2xl md:text-3xl font-bold text-primary-light dark:text-primary-dark">
                Cut Sequence
              </h3>
              <button
                onClick={async () => {
                  toast.dismiss();
                  try {
                    await navigator.clipboard.writeText(cutSequence);
                    toast.success("Sequence copied to clipboard!");
                  } catch (error) {
                    toast.error("Failed to copy!");
                  }
                }}>
                <img
                  className="h-8 w-8 duration-200 rounded-md bg-primary-btn-light dark:bg-primary-btn-dark hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark p-1"
                  src={
                    "https://storage.googleapis.com/gpp4-bucket/src/light-copy.svg"
                  }
                  alt="clipboard icon to copy cut sequence"
                />
              </button>
            </div>
            <p className="mono-font break-words text-wrap text-secondary-light dark:text-secondary-dark">
              {cutSequence}
            </p>
          </div>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
