export default function SequenceResult({
  result,
  handleDomainIdChange,
  canSelect = false,
}) {
  return (
    <tr>
      <td className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark tdx-1 py-2 border">
        <button
          className={`${
            canSelect
              ? "cursor-pointer text-blue-500 hover:text-blue-600 dark:text-blue-600 dark:hover:text-blue-700 duration-200 hover:underline"
              : "cursor-not-allowed"
          } w-full`}
          onClick={
            canSelect
              ? () =>
                  handleDomainIdChange({
                    value: result.alignment_hit_id,
                    error: "",
                  })
              : () => {}
          }>
          {result.alignment_hit_id}
        </button>
      </td>
      <td className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
        {result.alignment_length}
      </td>
      <td className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
        {result.alignment_title}
      </td>
      <td className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
        {result.hsp_expect}
      </td>
      <td className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
        {result.identity}
      </td>
      <td className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
        {result.query_coverage}
      </td>
    </tr>
  );
}
