import PropTypes from "prop-types";

import { useContext } from "react";

import { GlobalContext } from "../../contexts/GlobalContext";
import { getImageUrl } from "../../utils/helpers";

export default function SettingsLogOutButton({
  clearUser = () => {},
  displayOptions,
  setThemeDisplayOptions,
  setLogoutDisplayOptions,
}) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  return displayOptions ? (
    <div className="mx-auto space-x-2">
      <button
        onClick={() => clearUser()}
        className="hover:bg-primary-btn-light dark:hover:bg-primary-btn-dark hover:text-primary-dark dark:text-primary-dark text-primary-light px-4 py-2 mx-auto space-x-2 text-center transition-all duration-200 rounded-md">
        Log out
      </button>
      <button
        onClick={() => {
          setThemeDisplayOptions(false);
          setLogoutDisplayOptions(false);
        }}
        className="dark:hover:bg-red-700 text-primary-dark dark:bg-red-600 hover:bg-red-600 px-4 py-2 mx-auto space-x-2 text-center transition-all duration-200 bg-red-500 rounded-md">
        Cancel
      </button>
    </div>
  ) : (
    <button
      onClick={() => {
        setThemeDisplayOptions(false);
        setLogoutDisplayOptions(true);
      }}
      className="hover:bg-primary-btn-light dark:hover:bg-primary-btn-dark hover:text-primary-dark text-primary-light dark:text-primary-dark hover:py-2 flex items-center justify-center px-4 mx-auto space-x-2 text-center transition-all duration-200 rounded-md">
      <img
        src={getImageUrl("log-out.svg", isDark)}
        alt="Display log out icon and toggle log out options"
        className="w-5 h-5"
      />
      <span>Log out</span>
    </button>
  );
}

SettingsLogOutButton.propTypes = {
  clearUser: PropTypes.func.isRequired,
  displayOptions: PropTypes.bool.isRequired,
  setThemeDisplayOptions: PropTypes.func.isRequired,
  setLogoutDisplayOptions: PropTypes.func.isRequired,
};
