import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Full link: Link where the entire text is clickable
// Partial link: Link where only a part of the text is clickable
// Custom link: Component to render either a full or partial link

// Component to render the full link
function FullLink({ to, children }) {
  return (
    <Link
      className="hover:underline hover:text-link-hover dark:hover:text-link-hoverDark text-link-light dark:text-link-dark text-sm duration-200"
      to={to}>
      {children}
    </Link>
  );
}

FullLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// Component to render the partial link
function PartialLink({ to, partialText, children }) {
  return (
    <span className="text-secondary-light dark:text-secondary-dark text-sm duration-200">
      {`${partialText} `}
      <FullLink to={to}>{children}</FullLink>
    </span>
  );
}

PartialLink.propTypes = {
  to: PropTypes.string.isRequired,
  partialText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// Component to render either a full or partial link based on the partialText prop
export default function CustomLink({ to, children, partialText = "" }) {
  return partialText.trim().length > 0 ? (
    <PartialLink to={to} partialText={partialText.trim()}>
      {children}
    </PartialLink>
  ) : (
    <FullLink to={to}>{children}</FullLink>
  );
}

CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  partialText: PropTypes.string,
};
