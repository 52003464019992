import PropTypes from "prop-types";

import { useContext, useState } from "react";

import axios from "axios";

import toast from "react-hot-toast";

import { GlobalContext } from "../../contexts/GlobalContext";
import { API_URL } from "../../utils/api";
import { getImageUrl } from "../../utils/helpers";
import { useSearchParams } from "react-router-dom";

export default function CreateNewPortfolioForm({ closeForm, setPortfolios }) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  function validateData() {
    let isValid = true;
    setNameError("");
    if (!name.trim()) {
      isValid = false;
      setNameError("Portfolio name is required");
    }
    return isValid;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();
    setIsLoading(true);
    if (validateData()) {
      try {
        const response = await axios.post(
          `${API_URL}/pdf_docs/create_portfolio?project_id=${searchParams.get(
            "project"
          )}&portfolio_name=${name}`
        );

        if (response.status !== 201) throw response;

        toast.success("Portfolio created successfully!");
        const newPortfolio = {
          id: response.data.portfolio_id,
          name: name,
          pdfs: 0,
          updatedAt: null,
        };
        setPortfolios((prev) => [...prev, newPortfolio]);
        closeForm();
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while processing your request, please try again."
            );
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <button
        className="top-4 right-4 lg:top-2 lg:right-2 absolute"
        onClick={closeForm}>
        <img
          src={getImageUrl("close.svg", isDark)}
          alt="close"
          className="hover:bg-tertiary-light dark:hover:bg-tertiary-dark w-8 h-8 p-1 duration-200 rounded-full cursor-pointer"
        />
      </button>
      <h2 className="text-secondary-light dark:text-secondary-dark text-2xl font-bold">
        Create New Portfolio
      </h2>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center w-full space-y-8">
        <div className="flex flex-col items-start w-full space-y-2">
          <label
            htmlFor="projectName"
            className="text-tertiary-light dark:text-tertiary-dark font-semibold">
            * Portfolio Name
          </label>
          <input
            name="projectName"
            id="projectName"
            type="text"
            placeholder="(max: 50 characters)"
            className={`${
              nameError ? "!border-red-500" : "!border-light dark:!border-dark"
            } w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:!bg-gray-200 dark:disabled:!bg-gray-700`}
            onChange={(e) => {
              e.target.value.length > 50
                ? setNameError("Character limit reached")
                : setNameError("");
              setName((prev) =>
                e.target.value.length > 50 ? prev : e.target.value
              );
            }}
            value={name}
          />
          {nameError && <p className="text-red-500">{nameError}</p>}
        </div>

        <div className="w-full space-y-4">
          <button
            className="enabled:hover:bg-blue-600 dark:bg-blue-600 dark:enabled:hover:bg-blue-700 rounded-xl disabled:cursor-not-allowed disabled:bg-opacity-70 hover:bg-blue-600 w-full px-4 py-2 text-lg font-semibold text-white duration-200 bg-blue-500"
            disabled={isLoading}>
            {isLoading ? (
              <img
                src="https://storage.googleapis.com/gpp4-bucket/src/light-loader.svg"
                alt="loader"
                className="w-7 h-7 mx-auto"
              />
            ) : (
              "Create Portfolio"
            )}
          </button>
          <p className="dark:text-gray-300 text-xs text-center">
            * Required field
          </p>
        </div>
      </form>
    </>
  );
}

CreateNewPortfolioForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  setPortfolios: PropTypes.func.isRequired,
};
