import PropTypes from "prop-types";

export default function CreateNewProjectButton({ openForm }) {
  return (
    <div className="space-y-4">
      <p className="text-secondary-light dark:text-secondary-dark text-lg font-semibold">
        Start by creating a new project
      </p>
      <button
        className="enabled:hover:bg-blue-600 dark:bg-blue-600 dark:enabled:hover:bg-blue-700 rounded-xl disabled:cursor-not-allowed disabled:bg-opacity-70 hover:bg-blue-600 w-full px-4 py-2 text-lg font-semibold text-white duration-200 bg-blue-500"
        onClick={openForm}>
        Create New Project
      </button>
    </div>
  );
}

CreateNewProjectButton.propTypes = {
  openForm: PropTypes.func.isRequired,
};
