import NextButton from "./NextButton";
import Pages from "./Pages";
import PreviousButton from "./PreviousButton";

export default function PaginationControls({ currentPage, totalPages, handlePageChange }) {
  return (
    <section className="flex justify-between items-center">
      <PreviousButton currentPage={currentPage} handlePageChange={handlePageChange} />
      <Pages currentPage={currentPage} totalPages={totalPages} />
      <NextButton currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
    </section>
  );
}
