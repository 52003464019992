export default function SubmitButton({ disabled }) {
  return (
    <button
      className="enabled:hover:bg-blue-600 dark:bg-blue-600 dark:enabled:hover:bg-blue-700 rounded-xl disabled:cursor-not-allowed disabled:bg-opacity-70 hover:bg-blue-600 sm:w-fit w-full px-4 py-2 text-lg font-semibold text-white duration-200 bg-blue-500"
      disabled={disabled}
      type="submit">
      Save
    </button>
  );
}
