export default function InterproLink({ id }) {
  return (
    <p className="border-light dark:border-dark text-tertiary-light dark:text-tertiary-dark px-2 py-6 text-center border rounded-lg cursor-default">
      Analyze and verify functional domains from the protein accessing{" "}
      <a
        target="_blank"
        rel="noreferrer"
        className="hover:text-blue-600 dark:text-blue-600 dark:hover:text-blue-700 text-blue-500 underline duration-200"
        href={`https://www.ebi.ac.uk/interpro/result/InterProScan/${id}`}>
        InterPro data link
      </a>
    </p>
  );
}
