import { useContext } from "react";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { getImageUrl } from "../../../utils/helpers";

export default function FastaLoader() {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  return (
    <div className="min-h-44 w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark flex justify-center items-center">
      <img
        className="h-14 mx-auto"
        src={getImageUrl("loader.svg", isDark)}
        alt="loading svg"
      />
    </div>
  );
}
