import { useScrollIntoView } from "../../../hooks/useScrollIntoView";

import FastaLoader from "./FastaLoader";

export default function FastaDisplay({
  isLoading,
  fastaSequence,
  fastaError,
  handleFastaChange,
  identifier = "fasta",
  placeholder = "Once you do a search, the FASTA sequence will be displayed here.",
}) {
  const ref = useScrollIntoView();
  return (
    <>
      {isLoading && <FastaLoader />}
      {!isLoading && (
        <>
          <label
            className="font-medium text-tertiary-light dark:text-tertiary-dark"
            htmlFor={identifier}>
            FASTA Sequence recovered from previous search
          </label>
          <textarea
            disabled
            className="min-h-44 w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
            name={identifier}
            id={identifier}
            placeholder={placeholder}
            value={fastaSequence}
            onChange={({ target }) =>
              handleFastaChange({ value: target.value, error: "" })
            }
          />
          {fastaError && <p className="text-red-500">{fastaError}</p>}
          <div ref={ref} />
        </>
      )}
    </>
  );
}
