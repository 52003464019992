import PropTypes from "prop-types";

export default function PasswordErrorContainer({ children }) {
  return (
    <div className="flex items-center justify-between w-full">{children}</div>
  );
}

PasswordErrorContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
