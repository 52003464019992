import PropTypes from "prop-types";

import ToolButtonLink from "./ToolButtonLink";
import ToolButton from "./ToolButton";

export default function Tool({ tool }) {
  const { name, description, enabled, link } = tool;
  return (
    <li className="rounded-2xl hover:shadow-inner dark:hover:shadow-secondary-dark hover:shadow-secondary-light bg-secondary-light dark:bg-secondary-dark border-light dark:border-dark dark:shadow-secondary-dark shadow-secondary-light group flex flex-col justify-between p-6 space-y-4 duration-300 border shadow">
      <h3 className="text-primary-light dark:text-primary-dark text-3xl font-bold">
        {name}
      </h3>
      <p className="text-secondary-light dark:text-secondary-dark">
        {description}
      </p>
      {/* ToolButtonLink is used for accessibility to show the url while the button is used only to display the same styles but it does not have a set action */}
      {enabled ? <ToolButtonLink link={link} /> : <ToolButton />}
    </li>
  );
}

Tool.propTypes = {
  tool: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};
