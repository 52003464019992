import { useState, useEffect, useContext } from "react";

import axios from "axios";

import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../contexts/GlobalContext";
import { API_URL } from "../utils/api";

export default function useRetrieveProjects() {
  const [projects, setProjects] = useState([]);
  const { state, dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    const loadProjects = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await axios.post(`${API_URL}/users/get_user_projects`);
        if (response.status !== 200) throw response;
        setProjects(response.data);
      } catch (error) {
        toast.dismiss();
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while retrieving projects. Please try again later."
            );
        navigate("/");
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };
    loadProjects();
  }, [navigate, dispatch]);

  return {
    projects,
    setProjects,
    loadingInformation: state.loadingInformation,
  };
}
