import toolsData from "../data/tools.json";
import MainLayout from "../layouts/MainLayout";
import ContentLayout from "../layouts/ContentLayout";
import PageTitle from "../components/ui/PageTitle";
import Tool from "../components/toolbox/Tool";
import Tools from "../components/toolbox/Tools";

toolsData.sort((a, b) => a.name.localeCompare(b.name));
const tools = [
  ...toolsData.filter((tool) => tool.enabled),
  ...toolsData.filter((tool) => !tool.enabled),
];

export default function ToolBox() {
  return (
    <MainLayout>
      <ContentLayout>
        <PageTitle>Toolbox</PageTitle>
        <Tools>
          {tools.map((tool) => (
            <Tool key={tool.id} tool={tool} />
          ))}
        </Tools>
      </ContentLayout>
    </MainLayout>
  );
}
