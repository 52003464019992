export default function SearchForm({
  labelValue,
  identifier,
  handleSubmit,
  children,
}) {
  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
      <label
        className="text-secondary-light dark:text-secondary-dark font-semibold"
        htmlFor={identifier}>
        {labelValue}
      </label>
      {children}
    </form>
  );
}
