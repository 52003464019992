export default function SearchButton({ isLoading, disabled = false }) {
  return (
    <button
      type="submit"
      disabled={disabled}
      className="bg-primary-btn-light dark:bg-primary-btn-dark text-white rounded-r-lg px-8 enabled:hover:bg-primary-btn-hover enabled:dark:hover:bg-primary-btn-hoverDark duration-200 w-1/3 disabled:cursor-not-allowed disabled:bg-opacity-70 max-h-[42px]">
      {isLoading ? (
        <img
          className="w-7 h-7 mx-auto"
          src="https://storage.googleapis.com/gpp4-bucket/src/light-loader.svg"
          alt="loading svg"
        />
      ) : (
        "Search"
      )}
    </button>
  );
}
