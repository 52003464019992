import { useEffect } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import toast from "react-hot-toast";

import modules from "../data/modules.json";

function moduleIsEnabled(moduleId) {
  const module = modules.find((module) => module.id === moduleId);
  return !module ? false : module.enabled;
}

export default function useValidateModule() {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project");
  const moduleId = Number(searchParams.get("module"));

  const navigate = useNavigate();

  useEffect(() => {
    if (!moduleId) return;
    if (!moduleIsEnabled(moduleId)) {
      navigate(`/projects?project=${projectId}`);
      toast.dismiss();
      toast.error("This module is not available for this project.");
    }
  }, [navigate, moduleId, projectId]);

  return { moduleId, projectId };
}
