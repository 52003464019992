import { useState } from "react";

export default function PaginatedTable({ items }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="space-y-4 w-full pt-4">
      <div className="flex justify-between items-center">
        <button
          className="dark:bg-secondary-btn-dark bg-secondary-btn-light enabled:hover:bg-secondary-btn-hover enabled:dark:hover:bg-secondary-btn-hoverDark text-secondary-light dark:text-secondary-dark md:w-1/6 disabled:cursor-not-allowed disabled:bg-opacity-70 w-1/3 px-4 py-2 font-semibold duration-200 rounded-lg"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}>
          Previous
        </button>
        <span className="text-tertiary-light dark:text-tertiary-dark">
          {currentPage} of {totalPages}
        </span>
        <button
          className="dark:bg-secondary-btn-dark bg-secondary-btn-light enabled:hover:bg-secondary-btn-hover enabled:dark:hover:bg-secondary-btn-hoverDark text-secondary-light dark:text-secondary-dark md:w-1/6 disabled:cursor-not-allowed disabled:bg-opacity-70 w-1/3 px-4 py-2 font-semibold duration-200 rounded-lg"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
      <div className="w-full overflow-scroll">
        <table className="w-full border border-light dark:border-dark">
          <thead>
            <tr className="dark:bg-tertiary-dark bg-tertiary-light">
              <th className=" text-tertiary-light dark:text-tertiary-dark px-1 py-2">
                Result
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr
                key={`${item}_${index}`}
                className="even:bg-secondary-light dark:even:bg-secondary-dark even:text-primary-light dark:even:text-primary-dark">
                <td className="text-secondary-light dark:text-secondary-dark p-2">
                  {item}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
