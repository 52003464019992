import ErrorMessage from "../../ui/ErrorMessage";

export default function SearchForm({
  labelValue,
  identifier,
  handleSubmit,
  children,
  error,
}) {
  return (
    <div className="space-y-2">
      <label
        className="text-secondary-light dark:text-secondary-dark font-semibold"
        htmlFor={identifier}>
        {labelValue}
      </label>
      <form className="flex duration-200 rounded-lg" onSubmit={handleSubmit}>
        {children}
      </form>
      <ErrorMessage error={error} />
    </div>
  );
}
