import PortfolioSelector from "../module5/PortfolioSelector";
import PageHeader from "../ui/PageHeader";
import PageTitle from "../ui/PageTitle";
import useValidateProject from "../../hooks/useValidateProject";
import ModuleSelectorButton from "../project_details/ui/ModuleSelectorButton";

export default function Module5() {
  const {
    loadingInformation,
    id: projectId,
    portfolioId,
  } = useValidateProject();

  return (
    <>
      {!loadingInformation && (
        <PageHeader
          to={
            portfolioId
              ? `/projects?project=${projectId}&module=5`
              : `/projects?project=${projectId}`
          }
          title="Module 5">
          <ModuleSelectorButton projectId={projectId} moduleId={5} />
        </PageHeader>
      )}
      {!portfolioId && <PortfolioSelector />}
      {portfolioId && <PageTitle>Portfolio Showcase Goes Here</PageTitle>}
    </>
  );
}
