import PropTypes from "prop-types";

export default function CancelButton({ isLoading = false, handleClick }) {
  return (
    <button
      className="enabled:hover:bg-red-600 dark:bg-red-600 dark:enabled:hover:bg-red-700 rounded-xl disabled:cursor-not-allowed disabled:bg-opacity-70 hover:bg-red-600 sm:w-fit w-full px-4 py-2 text-lg font-semibold text-white duration-200 bg-red-500"
      disabled={isLoading}
      type="button"
      onClick={handleClick}>
      Cancel
    </button>
  );
}

CancelButton.propTypes = {
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};
