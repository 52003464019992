import PropTypes from "prop-types";

export default function HeaderMenuButton({ setIsOpen, children, image }) {
  return (
    <button
      onClick={() => setIsOpen((prev) => !prev)}
      className={`border-light dark:border-dark bg-primary-btn-light hover:bg-primary-btn-hover dark:bg-primary-btn-dark dark:hover:bg-primary-btn-hoverDark rounded-xl text-primary-dark md:w-fit flex items-center justify-center w-full px-6 py-3 space-x-2 text-center duration-200 font-semibold`}>
      <span>{children}</span>
      <img
        className="w-6 h-6"
        src={`https://storage.googleapis.com/gpp4-bucket/src/light-${image}.svg`}
        alt={`Icon for ${children}`}
      />
    </button>
  );
}

HeaderMenuButton.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
