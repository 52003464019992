import PropTypes from "prop-types";

import GoBackButton from "../ui/GoBackButton";
import PageTitle from "../ui/PageTitle";
import Spacer from "../ui/Spacer";

export default function PageHeader({ children, to, title }) {
  return (
    <div className="container sticky top-0 z-50 pb-6 pt-2 bg-primary-light dark:bg-primary-dark">
      <div className="justify-between md:flex items-center hidden w-full space-x-6">
        <GoBackButton route={to} />
        <PageTitle>{title}</PageTitle>
        {children}
      </div>
      <div className="md:hidden flex flex-col items-center w-full space-y-4">
        <PageTitle>{title}</PageTitle>
        <div className="justify-between flex items-center w-full space-x-6">
          <GoBackButton route={to} />
          {children}
        </div>
      </div>
      <Spacer className={"mt-6"} />
    </div>
  );
}

PageHeader.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
