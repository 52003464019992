import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function ToolLink({ tool, open = false }) {
  return (
    <Link
      className={`hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark bg-primary-btn-light dark:bg-primary-btn-dark px-4 py-2 text-center text-white duration-200 rounded-lg ${
        open ? "visible opacity-100" : "opacity-0 invisible"
      } transition-all duration-300`}
      to={tool.link}>
      {tool.name}
    </Link>
  );
}

ToolLink.propTypes = {
  open: PropTypes.bool.isRequired,
  tool: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};
