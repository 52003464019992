import PropTypes from "prop-types";

import { useState } from "react";

import { NavLink, Link } from "react-router-dom";

import Spacer from "../ui/Spacer";
import { getImageUrl } from "../../utils/helpers";
import { navLinkClasses } from "../../utils/navbar/helpers";

import MenuButtonsContainer from "./MenuButtonsContainer";
import SettingsButton from "./SettingsButton";
import SettingsLogOutButton from "./SettingsLogOutButton";
import SettingsThemeButton from "./SettingsThemeButton";

export default function DesktopNavBar({ clearUser, isDark }) {
  const [openMenu, setOpenMenu] = useState(false);
  const [logoutDisplayOptions, setLogoutDisplayOptions] = useState(false);
  const [themeDisplayOptions, setThemeDisplayOptions] = useState(false);

  function handleClick() {
    setOpenMenu(false);
    setLogoutDisplayOptions(false);
    setThemeDisplayOptions(false);
  }

  return (
    <nav className="hidden md:flex items-center justify-between min-h-[12dvh] drop-shadow-lg container">
      <section className="flex items-center space-x-8">
        <Link onClick={handleClick} to="/">
          <img
            src={getImageUrl("logo.png", isDark)}
            alt="Logo"
            className="hover:cursor-pointer h-16"
          />
        </Link>
        <div className="space-x-4">
          <NavLink
            className={({ isActive }) => navLinkClasses(isActive, openMenu)}
            onClick={handleClick}
            to="/">
            Home
          </NavLink>
          <NavLink
            className={({ isActive }) => navLinkClasses(isActive, openMenu)}
            onClick={handleClick}
            to="/projects">
            Projects
          </NavLink>
          <NavLink
            className={({ isActive }) => navLinkClasses(isActive, openMenu)}
            onClick={handleClick}
            to="/tools">
            Tools
          </NavLink>
        </div>
      </section>
      <SettingsButton
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        setLogoutDisplayOptions={setLogoutDisplayOptions}
        setThemeDisplayOptions={setThemeDisplayOptions}
      />
      <MenuButtonsContainer openMenu={openMenu}>
        <SettingsThemeButton
          isDark={isDark}
          displayOptions={themeDisplayOptions}
          setThemeDisplayOptions={setThemeDisplayOptions}
          setLogoutDisplayOptions={setLogoutDisplayOptions}
        />
        <Spacer />
        <SettingsLogOutButton
          clearUser={clearUser}
          displayOptions={logoutDisplayOptions}
          setThemeDisplayOptions={setThemeDisplayOptions}
          setLogoutDisplayOptions={setLogoutDisplayOptions}
        />
      </MenuButtonsContainer>
    </nav>
  );
}

DesktopNavBar.propTypes = {
  clearUser: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};
