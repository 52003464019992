import axios from "axios";

import { useState, useEffect, useContext } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import toast from "react-hot-toast";

import { GlobalContext } from "../contexts/GlobalContext";
import { API_URL } from "../utils/api";

export default function useRetrieveProjectInfo() {
  const [projectDetails, setProjectDetails] = useState({});
  const [projectNotes, setProjectNotes] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    const getUserProjects = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await axios.post(
          `${API_URL}/users/get_proyect_info?project_id=${searchParams.get(
            "project"
          )}`
        );
        if (response.status !== 200) throw response;
        else if (response.data?.error === "Project does not belong to user.") {
          response.data.detail = "This project does not exist.";
          throw response;
        } else {
          setProjectDetails(response.data);
          setProjectNotes(response.data.notes);
        }
      } catch (error) {
        toast.dismiss();
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while processing your request, please try again."
            );
        navigate("/projects");
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };
    getUserProjects();
  }, [navigate, searchParams, dispatch]);

  return {
    projectDetails,
    notes: projectNotes,
    setNotes: setProjectNotes,
    loadingInformation: state.loadingInformation,
  };
}
