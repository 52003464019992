import axios from "axios";

import toast from "react-hot-toast";

import { useState } from "react";

import { useScrollIntoView } from "../../hooks/useScrollIntoView";
import useValidateProject from "../../hooks/useValidateProject";
import ContentLayout from "../../layouts/ContentLayout";
import { API_URL } from "../../utils/api";

import BioprocessReport from "../module2/BioprocessReport";
import ErrorMessage from "../ui/ErrorMessage";
import PageHeader from "../ui/PageHeader";
import ModuleSelectorButton from "../project_details/ui/ModuleSelectorButton";

const generateBaseState = () => {
  return {
    substrate: "",
    product: "",
    microorganism: "",
  };
};

export default function Module2() {
  const { id: projectId, loadingInformation } = useValidateProject();
  const ref = useScrollIntoView();
  const [values, setValues] = useState(generateBaseState());
  const [errors, setErrors] = useState(generateBaseState());
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  function validateData() {
    const newErrors = { ...generateBaseState() };
    let isValid = true;
    if (!values.substrate.trim()) {
      newErrors.substrate = "Substrate is required";
      isValid = false;
    }
    if (!values.product.trim()) {
      newErrors.product = "Product is required";
      isValid = false;
    }
    if (!values.microorganism.trim()) {
      newErrors.microorganism = "Microorganism is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    if (validateData()) {
      try {
        const { substrate, product, microorganism } = values;
        const response = await axios.get(
          `${API_URL}/kegg/V2/kegg_module?substrate=${substrate}&product=${product}&organism=${microorganism}`
        );
        if (response.status !== 200) throw response;
        if (response.data === null) {
          toast.dismiss();
          toast.error("No results found for the given parameters.");
        }
        setData(response.data);
      } catch (error) {
        toast.dismiss();
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error("An error occurred while searching for results.");
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      {!loadingInformation && (
        <PageHeader to={`/projects?project=${projectId}`} title="Module 2">
          <ModuleSelectorButton projectId={projectId} moduleId={2} />
        </PageHeader>
      )}
      <ContentLayout>
        <form onSubmit={handleSubmit} className="flex flex-col w-full">
          <div ref={ref} />
          <section className="flex flex-col space-y-8 w-full lg:flex-row lg:space-y-0 lg:space-x-4 lg:py-4 lg:justify-between lg:items-start mb-6">
            <div className="flex flex-col space-y-2 lg:w-full">
              <label
                className="text-secondary-light dark:text-secondary-dark font-semibold"
                htmlFor="substrate">
                Substrate
              </label>
              <input
                className={`rounded-lg w-full p-2 bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border outline-none  placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark ${
                  errors.substrate
                    ? "border-red-500"
                    : "border-light dark:border-dark dark:focus:!border-blue-400 focus:!border-blue-500"
                }`}
                type="text"
                name="substrate"
                id="substrate"
                placeholder="Enter Substrate Name"
                value={values.substrate}
                disabled={isLoading}
                onChange={({ target }) => {
                  setErrors({ ...errors, substrate: "" });
                  setValues({ ...values, substrate: target.value });
                }}
              />
              <ErrorMessage error={errors.substrate} />
            </div>
            <div className="flex flex-col space-y-2 lg:w-full">
              <label
                className="text-secondary-light dark:text-secondary-dark font-semibold"
                htmlFor="product">
                Product
              </label>
              <input
                className={`rounded-lg w-full p-2 bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border outline-none  placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark ${
                  errors.product
                    ? "border-red-500"
                    : "border-light dark:border-dark dark:focus:!border-blue-400 focus:!border-blue-500"
                }`}
                type="text"
                name="product"
                id="product"
                placeholder="Enter Product Name"
                disabled={isLoading}
                value={values.product}
                onChange={({ target }) => {
                  setErrors({ ...errors, product: "" });
                  setValues({ ...values, product: target.value });
                }}
              />
              <ErrorMessage error={errors.product} />
            </div>
            <div className="flex flex-col space-y-2 lg:w-full">
              <label
                className="text-secondary-light dark:text-secondary-dark font-semibold"
                htmlFor="microorganism">
                Microorganism
              </label>
              <input
                className={`rounded-lg w-full p-2 bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border outline-none  placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark ${
                  errors.microorganism
                    ? "border-red-500"
                    : "border-light dark:border-dark dark:focus:!border-blue-400 focus:!border-blue-500"
                }`}
                type="text"
                name="microorganism"
                id="microorganism"
                placeholder="Enter Microorganism Name"
                disabled={isLoading}
                value={values.microorganism}
                onChange={({ target }) => {
                  setErrors({ ...errors, microorganism: "" });
                  setValues({ ...values, microorganism: target.value });
                }}
              />
              <ErrorMessage error={errors.microorganism} />
            </div>
          </section>
          <button
            type="submit"
            className="enabled:hover:bg-blue-600 dark:bg-blue-600 dark:enabled:hover:bg-blue-700 rounded-xl disabled:cursor-not-allowed disabled:bg-opacity-70 hover:bg-blue-600 w-full px-4 py-2 text-lg font-semibold text-white duration-200 bg-blue-500"
            disabled={isLoading}>
            {isLoading ? (
              <img
                className="w-7 h-7 mx-auto"
                src="https://storage.googleapis.com/gpp4-bucket/src/light-loader.svg"
                alt="Loading state on button"
              />
            ) : (
              "Search"
            )}
          </button>
        </form>
        {data && (
          <>
            <header className="space-y-4">
              <h2 className="text-2xl font-bold text-primary-light dark:text-primary-dark">
                Bioprocess Report
              </h2>
              <div>
                <h3 className="text-xl font-bold text-secondary-light dark:text-secondary-dark">
                  {data?.organism.name}
                </h3>
                <p className="text-sm text-tertiary-light dark:text-tertiary-dark">
                  {data?.organism.abbreviation} ({data?.organism.organism_code})
                </p>
              </div>
            </header>
            <BioprocessReport report={data?.bioprocess_report} />
          </>
        )}
      </ContentLayout>
    </>
  );
}
