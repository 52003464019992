import PropTypes from "prop-types";

import { Link } from "react-router-dom";

export default function GoBackButton({ route }) {
  return (
    <Link
      to={route}
      className="border-light dark:border-dark bg-primary-btn-light hover:bg-primary-btn-hover dark:bg-primary-btn-dark dark:hover:bg-primary-btn-hoverDark rounded-xl text-primary-dark flex items-center justify-center px-4 py-2 pr-6 text-center duration-200 w-1/2 md:w-fit font-semibold text-lg">
      <img
        className="w-8 h-8"
        src="https://storage.googleapis.com/gpp4-bucket/src/light-go-back.svg"
        alt="Icon to go back displaying an arrow pointing left on a button"
      />
      <span>Go back</span>
    </Link>
  );
}

GoBackButton.propTypes = {
  route: PropTypes.string.isRequired,
};
