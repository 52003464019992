import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import Spacer from "./Spacer";

export default function Footer() {
  return (
    <footer className="bg-secondary-light bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30  dark:bg-secondary-dark dark:text-gray-300 min-h-[24dvh] border-t-2 border-dark/50 dark:border-light/50 flex">
      <div className="md:justify-between md:items-center md:py-8 md:flex-row md:space-y-0 container flex flex-col justify-center px-3 md:px-0 py-6 space-y-6 text-center">
        <div className="md:text-left flex flex-col space-y-2">
          <h4 className="text-lg font-semibold">
            Gene Paradox Platform © 2024
          </h4>
          <a
            href="https://www.geneparadox.com/privacy_policy.html"
            target="_blank"
            rel="noreferrer"
            className="hover:cursor-pointer hover:font-normal hover:text-blue-500 transition duration-200">
            Privacy Policy
          </a>
          <a
            href="https://geneparadox.com/Terms_and_conditions.html"
            target="_blank"
            rel="noreferrer"
            className="hover:cursor-pointer hover:font-normal hover:text-blue-500 transition duration-200">
            Terms and Conditions
          </a>
          <a
            href="mailto:info@geneparadox.com"
            target="_blank"
            rel="noreferrer"
            className="hover:cursor-pointer hover:font-normal hover:text-blue-500 transition duration-200">
            Contact Us
          </a>
        </div>

        <div className="md:hidden w-full">
          <Spacer />
        </div>

        <div className="md:justify-normal md:items-end flex flex-col items-center justify-center space-y-4">
          <img
            className="max-w-[150px]"
            src="https://storage.googleapis.com/gpp4-bucket/src/google-cloud-startup.png"
            alt="Platform powered by Google for Startups"
          />
          <div className="flex flex-row justify-end">
            <a
              href="https://www.linkedin.com/company/geneparadox/mycompany/"
              target="_blank"
              rel="noreferrer">
              <FontAwesomeIcon
                className="hover:scale-125 hover:text-blue-500 w-8 h-8 duration-200 cursor-pointer"
                icon={faLinkedin}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
