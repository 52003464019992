import { Route, Routes } from "react-router-dom";

import AminoacidCounter from "../pages/AminoacidCounter";
import FastaConverter from "../pages/FastaConverter";
import GRASOrganism from "../pages/GRASOrganism";
import Home from "../pages/Home";
import NucleotideCounter from "../pages/NucleotideCounter";
import NucleotideToAminoacid from "../pages/NucleotideToAminoacid";
import Projects from "../pages/Projects";
import SequenceCutter from "../pages/SequenceCutter";
import ToolBox from "../pages/ToolBox";

export default function LoginRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Home />} />
      <Route path="/tools" element={<ToolBox />} />
      <Route path="/tools/sequence_cutter" element={<SequenceCutter />} />
      <Route path="/tools/nucleotide_counter" element={<NucleotideCounter />} />
      <Route path="/tools/aminoacid_counter" element={<AminoacidCounter />} />
      <Route path="/tools/fasta_converter" element={<FastaConverter />} />
      <Route path="/tools/gras_organism_list" element={<GRASOrganism />} />
      <Route
        path="/tools/nucleotide_translator"
        element={<NucleotideToAminoacid />}
      />
      <Route path="/projects" element={<Projects />} />
    </Routes>
  );
}
