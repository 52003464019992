import { useState } from "react";

import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import ToolsSelector from "../components/toolbox/ToolSelector";
import PageHeader from "../components/ui/PageHeader";

export default function NucleotideCounter() {
  const [sequence, setSequence] = useState("");

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Nucleotide Counter">
        <ToolsSelector id={2} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-4">
          <label
            htmlFor="fasta"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your FASTA Sequence
          </label>
          <textarea
            value={sequence}
            onChange={(e) => {
              if (e.target.value.trim() !== "") {
                const data = e.target.value.toUpperCase().split("\n");
                const res = data.filter((t) => !t.match(/[^ATCG]/g));
                if (res.length > 0) setSequence(res.join(""));
              } else if (e.target.value.trim() === "") setSequence("");
              else setSequence((prev) => prev);
            }}
            name="fasta"
            id="fasta"
            cols="30"
            rows="10"
            className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
            placeholder=">Sequence"
          />
        </section>
        <section className="flex flex-col space-y-4">
          <label
            htmlFor="length"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            Total Number of Molecules
          </label>
          <input
            className="!border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700"
            type="text"
            name="length"
            id="length"
            readOnly
            placeholder="# of Molecules"
            value={sequence.length || ""}
          />
        </section>
        <section className="space-y-4">
          <h3 className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            Nucleotides
          </h3>
          <div className="grid grid-cols-4 gap-4">
            <input
              className="col-span-2 md:col-span-1 !border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700 text-center"
              readOnly
              type="text"
              name="A"
              id="A"
              placeholder="# of A's"
              value={
                sequence.match(/A/g) ? "A = " + sequence.match(/A/g).length : ""
              }
            />
            <input
              className="col-span-2 md:col-span-1 !border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700 text-center"
              readOnly
              type="text"
              name="T"
              id="T"
              placeholder="# of T's"
              value={
                sequence.match(/T/g) ? "T = " + sequence.match(/T/g).length : ""
              }
            />
            <input
              className="col-span-2 md:col-span-1 !border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700 text-center"
              readOnly
              type="text"
              name="C"
              id="C"
              placeholder="# of C's"
              value={
                sequence.match(/C/g) ? "C = " + sequence.match(/C/g).length : ""
              }
            />
            <input
              className="col-span-2 md:col-span-1 !border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700 text-center"
              readOnly
              type="text"
              name="G"
              id="G"
              placeholder="# of G's"
              value={
                sequence.match(/G/g) ? "G = " + sequence.match(/G/g).length : ""
              }
            />
          </div>
        </section>
        <section className="flex flex-col space-y-4">
          <label
            htmlFor="gc"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            GC Content
          </label>
          <input
            className="!border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700"
            type="text"
            readOnly
            name="gc"
            id="gc"
            placeholder="GC Content"
            value={
              sequence.match(/[GC]/g)
                ? `${(
                    (sequence.match(/[GC]/g).length / sequence.length) *
                    100
                  ).toFixed(2)}%`
                : ""
            }
          />
        </section>
      </ContentLayout>
    </MainLayout>
  );
}
