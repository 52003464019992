import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../utils/api";
import { toast } from "react-hot-toast";
import FormHeader from "../components/ui/FormHeader";
import InputContainer from "../components/ui/InputContainer";
import InputField from "../components/ui/InputField";
import ErrorMessage from "../components/ui/ErrorMessage";
import SubmitButton from "../components/ui/SubmitButton";
import { validatePassword } from "../helpers/validation";

export default function RestorePassword() {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate("/login");
  }, [token, navigate]);

  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [newPasswordErrors, setNewPasswordErrors] = useState([]);
  const [repeatedPasswordError, setRepeatedPasswordError] = useState("");

  function validateData() {
    setNewPasswordErrors([]);
    setRepeatedPasswordError("");

    const errors = validatePassword(newPassword);

    if (errors.length === 0 && newPassword !== repeatedPassword) {
      errors.push("Passwords do not match");
      setRepeatedPasswordError("Check this one too!");
    }

    setNewPasswordErrors(errors);
    return errors.length === 0;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();

    if (validateData()) {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/users/restore_password`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ password: newPassword, token }),
          timeout: 30000,
        });

        if (response.status !== 200) throw response;

        toast.success("Password changed successfully!");
        navigate("/login");
      } catch (error) {
        const data = await error.json();
        toast.dismiss();
        data?.detail
          ? toast.error(`${data.detail}`)
          : toast.error("An error occurred while restoring password");
      } finally {
        setLoading(false);
      }
    }
  }
  return (
    <section className="bg-primary-light dark:bg-primary-dark min-h-dvh flex items-center justify-center px-4 py-8">
      <form
        onSubmit={handleSubmit}
        className="bg-secondary-light dark:bg-secondary-dark rounded-xl md:max-w-2xl w-full px-4 py-6 space-y-8 shadow-2xl">
        <FormHeader content="Restore your Password" />

        <InputContainer>
          <InputField
            type="password"
            id="newPassword"
            value={newPassword}
            error={newPasswordErrors.length > 0}
            setValue={setNewPassword}
            resetError={() => setNewPasswordErrors([])}
            required
          />
          {newPasswordErrors.length > 0 && (
            <ErrorMessage error={newPasswordErrors} />
          )}
        </InputContainer>

        <InputContainer>
          <InputField
            type="password"
            id="repeatedPassword"
            value={repeatedPassword}
            error={repeatedPasswordError}
            setValue={setRepeatedPassword}
            resetError={() => setRepeatedPasswordError("")}
            required
          />
          {repeatedPasswordError && (
            <ErrorMessage error={repeatedPasswordError} />
          )}
        </InputContainer>

        <div className="flex flex-col items-center space-y-4">
          <SubmitButton loading={loading} disabled={loading}>
            Change password
          </SubmitButton>
          <p className="dark:text-gray-300 text-xs text-center">
            * Required field
          </p>
        </div>
      </form>
    </section>
  );
}
