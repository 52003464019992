export function validatePassword(password) {
  const errors = [];
  if (password.length < 8) {
    errors.push("Must have at least 8 characters");
  }
  if (!/[a-z]/.test(password)) {
    errors.push("Must have at least one lowercase letter");
  }
  if (!/[A-Z]/.test(password)) {
    errors.push("Must have at least one uppercase letter");
  }
  if (!/\d/.test(password)) {
    errors.push("Must have at least one digit");
  }
  if (!/[@$!%*?&#]/.test(password)) {
    errors.push("Must have at least one special character: @ $ ! % * ? & #");
  }
  return errors;
}
