import { useEffect, useState } from "react";

import axios from "axios";

import toast from "react-hot-toast";

import { useScrollIntoView } from "../../hooks/useScrollIntoView";
import { API_URL } from "../../utils/api";

import SequenceCutter from "./SequenceCutter";

import InterproLink from "./ui/InterproLink";
import SearchButton from "./ui/SearchButton";
import SearchForm from "./ui/SearchForm";

export default function DomainSearch({
  domainSearchId,
  domainFasta,
  setDomainFasta,
  setCutSequence,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [interproIdentifier, setInterproIdentifier] = useState("");
  const [searching, setSearching] = useState(false);
  const [finished, setFinished] = useState(false);
  const ref = useScrollIntoView();

  useEffect(() => {
    setInterproIdentifier("");
  }, [domainSearchId]);

  useEffect(() => {
    setFinished(false);
  }, [interproIdentifier]);

  useEffect(() => {
    if (searching) return;
    toast.dismiss();
  }, [searching]);

  useEffect(() => {
    if (!finished) return;
  }, [finished]);

  useEffect(() => {
    if (!interproIdentifier || finished) return;
    if (!searching) setSearching(true);
    const interval = setInterval(async () => {
      try {
        const response = await axios.post(
          `${API_URL}/interpro/status?job_id=${interproIdentifier}`
        );
        if (response.status !== 200) throw response;
        if (response?.data === "FINISHED") {
          toast.dismiss();
          clearInterval(interval);
          setSearching(false);
          setFinished(true);
        }
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error("An error occurred while searching for domains");
        setSearching(false);
        setInterproIdentifier("");
        clearInterval(interval);
        toast.dismiss();
      }
    }, 7500);
    return () => {
      clearInterval(interval);
    };
  }, [interproIdentifier, searching, finished]);

  async function handleDomainSearch(e) {
    toast.dismiss();
    e.preventDefault();
    setIsLoading(true);
    try {
      toast.loading("Searching for domains\n\nThis process may take a while");

      const genbank_response = await axios.get(
        `${API_URL}/genbank/get/{id}?genbank_id=${domainSearchId.value}&db=protein`
      );
      if (genbank_response.status !== 200) throw genbank_response;

      const interpro_response = await axios.post(`${API_URL}/interpro/run`, {
        fasta: genbank_response.data.fasta_sequence,
      });
      if (interpro_response.status !== 200) throw interpro_response;

      setInterproIdentifier(interpro_response.data);
    } catch (error) {
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error("An error occurred while searching for domains");
    }
    setIsLoading(false);
  }

  return (
    <article className="space-y-4">
      <h4 className="font-semibold text-2xl text-secondary-light dark:text-secondary-dark">
        Domain Search
      </h4>
      <SearchForm
        labelValue={"Organism ID recovered from sequence alignment"}
        identifier={"organismId"}
        handleSubmit={handleDomainSearch}
        error={domainSearchId.error}>
        <div ref={ref} />
        <input
          className={`rounded-l-lg w-full p-2 bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border outline-none  placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark ${
            domainSearchId.error
              ? "border-red-500"
              : "border-light dark:border-dark dark:focus:!border-blue-400 focus:!border-blue-500"
          }`}
          type="text"
          id="organismId"
          name="organismId"
          placeholder="Organism ID recovered from sequence alignment"
          value={domainSearchId.value}
          onChange={() => {
            setInterproIdentifier("");
          }}
          disabled
          readOnly
        />
        <SearchButton
          isLoading={isLoading || searching}
          disabled={
            isLoading ||
            interproIdentifier ||
            !domainSearchId.value ||
            finished ||
            searching
          }
        />
      </SearchForm>

      {finished && interproIdentifier && (
        <>
          <InterproLink id={interproIdentifier} />
          <SequenceCutter
            value={domainSearchId.value}
            domainFasta={domainFasta}
            setDomainFasta={setDomainFasta}
            setCutSequence={setCutSequence}
          />
        </>
      )}
    </article>
  );
}
