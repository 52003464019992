import { useState } from "react";

import toast from "react-hot-toast";

import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import ToolsSelector from "../components/toolbox/ToolSelector";
import PageHeader from "../components/ui/PageHeader";

const availableWidths = [60, 90, 120, 150];

export default function FastaConverter() {
  const [title, setTitle] = useState(">");
  const [sequence, setSequence] = useState("");
  const [width, setWidth] = useState("");
  const [convertedSequence, setConvertedSequence] = useState("");

  return (
    <MainLayout>
      <PageHeader to="/tools" title="FASTA Converter">
        <ToolsSelector id={5} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-4">
          <label
            htmlFor="title"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert a title for your sequence
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => {
              setConvertedSequence("");
              e.target.value.trim() === ""
                ? setTitle(">")
                : setTitle(e.target.value);
            }}
            name="title"
            id="title"
            className="!border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700"
            placeholder=">Title"
          />
        </section>
        <section className="space-y-4">
          <label
            htmlFor="sequence"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            2. Insert your sequence
          </label>
          <textarea
            value={sequence}
            onChange={(e) => {
              setConvertedSequence("");
              setSequence(e.target.value.trim().toUpperCase());
            }}
            name="sequence"
            id="sequence"
            className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
            placeholder="Sequence"
            rows={5}
            cols={30}
          />
        </section>
        <section className="space-y-4">
          <label
            htmlFor="width"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            3. Select the width of the sequence
          </label>
          <select
            value={width}
            onChange={(e) => {
              setConvertedSequence("");
              setWidth(e.target.value);
            }}
            name="width"
            id="width"
            className="!border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700">
            <option value="">Select width</option>
            {availableWidths.map((w) => (
              <option key={w} value={w}>
                {w}
              </option>
            ))}
          </select>
        </section>
        <button
          disabled={
            title.trim() === ">" ||
            sequence.trim() === "" ||
            width === "" ||
            convertedSequence
          }
          onClick={() => {
            if (title.trim() === ">" || sequence.trim() === "" || width === "")
              toast.error("Please fill all the fields");
            else {
              const sequenceArray = sequence.split("");
              let fasta = `${title}\n`;
              for (let i = 0; i < sequenceArray.length; i += parseInt(width)) {
                fasta +=
                  sequenceArray.slice(i, i + parseInt(width)).join("") + "\n";
              }
              setConvertedSequence(fasta);
            }
          }}
          className="border-light dark:border-dark bg-primary-btn-light enabled:hover:bg-primary-btn-hover dark:bg-primary-btn-dark enabled:dark:hover:bg-primary-btn-hoverDark rounded-xl text-tertiary-dark px-6 py-3 space-x-2 text-center duration-200 disabled:bg-opacity-70  disabled:cursor-not-allowed">
          Convert
        </button>

        {convertedSequence && (
          <div className="w-full border p-4 rounded-xl shadow-lg shadow-light dark:shadow-dark space-y-4 border-light dark:border-dark">
            <div className="flex items-center justify-between space-x-2">
              <h3 className="text-2xl md:text-3xl font-bold text-primary-light dark:text-primary-dark">
                Converted Sequence
              </h3>
              <button
                onClick={async () => {
                  toast.dismiss();
                  try {
                    await navigator.clipboard.writeText(convertedSequence);
                    toast.success("Sequence copied to clipboard!");
                  } catch (error) {
                    toast.error("Failed to copy!");
                  }
                }}>
                <img
                  className="h-8 w-8 duration-200 rounded-md bg-primary-btn-light dark:bg-primary-btn-dark hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark p-1"
                  src={
                    "https://storage.googleapis.com/gpp4-bucket/src/light-copy.svg"
                  }
                  alt="clipboard icon to copy cut sequence"
                />
              </button>
            </div>
            <p className="mono-font break-words text-wrap text-secondary-light dark:text-secondary-dark">
              {convertedSequence}
            </p>
          </div>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
