import axios from "axios";

import toast from "react-hot-toast";

import { API_URL } from "../api";

export async function updateNotes(id, notes, setIsLoading, setProjectNotes) {
  setIsLoading(true);

  const trimmedNotes = notes.trim(); // Remove leading and trailing whitespaces

  try {
    const response = await axios.post(
      `${API_URL}/users/update_notes?project_id=${id}&notes=${trimmedNotes}`
    );
    if (response.status !== 200) throw response;

    setProjectNotes(trimmedNotes); // in case of successful API call, update project notes to avoid fetching again

    return { ok: true, message: "Notes updated successfully!" }; // return success message and ok status
  } catch (error) {
    // return error message and ok status
    return {
      ok: false,
      message: error?.data?.detail
        ? error.data.detail
        : "An error occurred while updating notes.",
    };
  } finally {
    setIsLoading(false); // set loading to false after API call
  }
}

async function handleDeleteProject(id, navigate, dispatch) {
  toast.dismiss();
  dispatch({ type: "SET_LOADING", payload: true });
  try {
    const response = await axios.post(
      `${API_URL}/users/delete_project?project_id=${id}`
    );
    if (response.status !== 200) throw response;
    navigate("/projects");
  } catch (error) {
    error?.data?.detail
      ? toast.error(`Error: ${error.data.detail}`)
      : toast.error(
          "An error occurred while processing your request, please try again."
        );
  } finally {
    document.body.style.overflow = "auto";
    dispatch({ type: "SET_LOADING", payload: false });
  }
}

export function showDeleteModal(id, navigate, dispatch) {
  toast.dismiss();
  document.body.style.overflow = "hidden";
  toast.loading(
    <div className="flex flex-col space-y-4 text-center">
      <span className="text-lg">
        Are you sure you want to <strong>delete this project</strong>?
      </span>
      <span className="text-balance text-sm">
        This action <strong>can't be undone</strong>
      </span>
      <div className="justify-evenly flex w-full">
        <button
          onClick={() => handleDeleteProject(id, navigate, dispatch)}
          className="hover:bg-red-600 hover:text-white w-1/3 p-2 duration-200 border-2 rounded-lg">
          Yes
        </button>
        <button
          onClick={() => {
            toast.dismiss();
            document.body.style.overflow = "auto";
          }}
          className="hover:bg-blue-600 w-1/3 p-2 text-white duration-200 bg-blue-500 rounded-lg">
          No
        </button>
      </div>
    </div>,
    { icon: null }
  );
}
