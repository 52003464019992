import ErrorMessage from "../../../ui/ErrorMessage";

export default function Input({ id, value, handleChange, placeholder }) {
  return (
    <article className="flex flex-col w-1/2 space-y-2">
      <label
        className="text-secondary-light dark:text-secondary-dark font-semibold"
        htmlFor={id}>
        {id.charAt(0).toUpperCase() + id.slice(1)}
      </label>
      <input
        id={id}
        name={id}
        value={value.value}
        onChange={handleChange}
        placeholder={placeholder}
        autoComplete="off"
        type="text"
        className={`rounded-lg w-full p-2 bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border outline-none  placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark ${
          value.error
            ? "border-red-500"
            : "border-light dark:border-dark dark:focus:!border-blue-400 focus:!border-blue-500"
        }`}
      />
      <ErrorMessage error={value.error} />
    </article>
  );
}
