import useAutocomplete from "../../../hooks/useAutocomplete";

import AutocompleteOptions from "./autocomplete/AutocompleteOptions";
import Input from "./autocomplete/Input";

export default function SearchInput({
  identifier,
  placeholder,
  value,
  setValue,
  readOnly,
  disabled,
  show = false,
  double = true,
}) {
  const { options, showOptions, setShowOptions, isLoading } = useAutocomplete(
    value,
    "/brenda/autocomplete_rn"
  );

  return (
    <div className={`w-full relative ${show && "flex flex-col"}`}>
      <Input
        identifier={identifier}
        placeholder={placeholder}
        value={value}
        setValue={setValue}
        setShowOptions={setShowOptions}
        readOnly={readOnly}
        disabled={disabled}
        double={double}
      />
      {showOptions && (
        <AutocompleteOptions
          isLoading={isLoading}
          options={options}
          setValue={setValue}
          show={show}
        />
      )}
    </div>
  );
}
