const BioprocessReport = ({ report }) => {
  return (
    <>
      {report?.map((pathwayGroup) =>
        pathwayGroup?.map((pathway, pathwayIndex) => (
          <div
            key={`${pathway?.pathway_code}_${pathwayIndex}`}
            className="border-light dark:border-dark p-4 space-y-4 border rounded-md">
            <h3 className="text-secondary-light dark:text-secondary-dark text-xl font-bold">
              {pathway?.pathway_name}
            </h3>
            <p className="text-tertiary-light dark:text-tertiary-dark text-sm">
              {pathway?.pathway_code}
            </p>
            {pathway?.matched_modules?.map((module, moduleIndex) => (
              <div
                key={`${module.module_code}_${moduleIndex}`}
                className="border-light dark:border-dark pt-4 mt-4 border-t">
                <h4 className="text-secondary-light dark:text-secondary-dark text-lg font-semibold">
                  {module?.name}
                </h4>
                <p className="text-tertiary-light dark:text-tertiary-dark text-sm">
                  {module?.module_code}
                </p>
                <Pathways pathways={module?.pathways} />
                <Orthology orthology={module?.founded_ways?.orthology} />
                <Reactions reactions={module?.founded_ways?.reactions} />
                <Compounds compounds={module?.founded_ways?.compounds} />
                <DfsProcess dfsProcess={module?.founded_ways?.dfs_process} />
                <FluxDeviations fluxDeviations={module?.flux_deviations} />
              </div>
            ))}
          </div>
        ))
      )}
    </>
  );
};

const Pathways = ({ pathways }) =>
  pathways !== undefined && (
    <div className="mt-2">
      <h4 className="text-secondary-light dark:text-secondary-dark text-lg font-semibold">
        Pathways
      </h4>
      <ul className="list-disc list-inside">
        {pathways.map((pathway, index) => (
          <li
            className="text-tertiary-light dark:text-tertiary-dark"
            key={`${pathway.code}_${index}`}>
            {pathway.description} ({pathway.code})
          </li>
        ))}
      </ul>
    </div>
  );

const Orthology = ({ orthology }) =>
  orthology !== undefined && (
    <div className="mt-2">
      <h4 className="text-secondary-light dark:text-secondary-dark text-lg font-semibold">
        Orthology
      </h4>
      <ul className="list-disc list-inside">
        {orthology.map((ortho, index) => (
          <li
            className="text-tertiary-light dark:text-tertiary-dark"
            key={`${ortho.name}_${index}`}>
            {ortho.name}: {ortho.enzyme_name} (EC: {ortho.ec_numbers},
            Reactions: {ortho.reactions})
          </li>
        ))}
      </ul>
    </div>
  );

const Reactions = ({ reactions }) =>
  reactions !== undefined && (
    <div className="mt-2">
      <h4 className="text-secondary-light dark:text-secondary-dark text-lg font-semibold">
        Reactions
      </h4>
      <ul className="list-disc list-inside">
        {reactions.map((reaction, index) => (
          <li
            className="text-tertiary-light dark:text-tertiary-dark"
            key={`${reaction.code}_${index}`}>
            {reaction.description} ({reaction.code})
          </li>
        ))}
      </ul>
    </div>
  );

const Compounds = ({ compounds }) =>
  compounds !== undefined && (
    <div className="mt-2">
      <h4 className="text-secondary-light dark:text-secondary-dark text-lg font-semibold">
        Compounds
      </h4>
      <ul className="list-disc list-inside">
        {compounds.map((compound, index) => (
          <li
            className="text-tertiary-light dark:text-tertiary-dark"
            key={`${compound.code}_${index}`}>
            {compound.name} ({compound.code})
          </li>
        ))}
      </ul>
    </div>
  );

const DfsProcess = ({ dfsProcess }) =>
  dfsProcess !== undefined && (
    <div className="mt-2">
      <h4 className="text-secondary-light dark:text-secondary-dark text-lg font-semibold">
        DFS Process
      </h4>
      <ul className="list-disc list-inside">
        {dfsProcess.map((process, index) => (
          <li
            className="text-tertiary-light dark:text-tertiary-dark"
            key={`${process.from}_${index}`}>
            {process.from} to {process.to.join(", ")}
          </li>
        ))}
      </ul>
    </div>
  );

const FluxDeviations = ({ fluxDeviations }) =>
  fluxDeviations !== undefined && (
    <div className="mt-2">
      <h4 className="text-secondary-light dark:text-secondary-dark text-lg font-semibold">
        Flux Deviations
      </h4>
      <ul className="list-disc list-inside">
        {fluxDeviations.map((deviation, index) => (
          <li
            className="text-tertiary-light dark:text-tertiary-dark"
            key={`${deviation.code}_${index}`}>
            {deviation.description} ({deviation.code})
          </li>
        ))}
      </ul>
    </div>
  );

export default BioprocessReport;
