import { useState } from "react";

import PageHeader from "../ui/PageHeader";
import Spacer from "../ui/Spacer";
import useRetrieveProjectInfo from "../../hooks/useRetrieveProjectInfo";
import ContentLayout from "../../layouts/ContentLayout";

import ProjectNotesDisplay from "./ProjectNotesDisplay";
import ProjectNotesForm from "./ProjectNotesForm";

import CreatedAt from "./ui/CreatedAt";
import DeleteProject from "./ui/DeleteProject";
import ModuleSelectorButton from "./ui/ModuleSelectorButton";
import Name from "./ui/Name";
import UpdatedAt from "./ui/UpdatedAt";

export default function ProjectDetails() {
  const { projectDetails, loadingInformation, notes, setNotes } =
    useRetrieveProjectInfo();
  const { project_id: id, name, createdAt, updatedAt } = projectDetails;

  const [canEditNotes, setCanEditNotes] = useState(false);

  return (
    <>
      {!loadingInformation && (
        <PageHeader to={`/projects`} title="Project Details">
          <ModuleSelectorButton projectId={id} />
        </PageHeader>
      )}
      <ContentLayout>
        <Name name={name || ""} />
        <CreatedAt date={createdAt || ""} />
        <UpdatedAt date={updatedAt || ""} />
        {canEditNotes ? (
          <ProjectNotesForm
            id={id}
            originalNotes={notes}
            setCanEditNotes={setCanEditNotes}
            setProjectNotes={setNotes}
          />
        ) : (
          <ProjectNotesDisplay
            notes={notes}
            setCanEditNotes={setCanEditNotes}
          />
        )}
        <Spacer />
        {id && (
          <DeleteProject
            id={id}
            disabled={loadingInformation}
            closeEditNotes={() => setCanEditNotes(false)}
          />
        )}
      </ContentLayout>
    </>
  );
}
