import PropTypes from "prop-types";

import { Toaster } from "react-hot-toast";

export default function CustomToaster({ isDark }) {
  return (
    <Toaster
      position="top-center"
      toastOptions={{
        style: {
          color: isDark ? "#d1d5db" : "#374151",
          backgroundColor: isDark ? "#374151" : '#d1d5db"',
          boxShadow: isDark
            ? "#1f2937 0px 4px 6px -1px, #1f2937 0px 2px 4px -1px"
            : "#e5e7eb 0px 4px 6px -1px, #e5e7eb 0px 2px 4px -1px",
        },
      }}
    />
  );
}

CustomToaster.propTypes = {
  isDark: PropTypes.bool.isRequired,
};
