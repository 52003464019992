import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import { getImageUrl } from "../../utils/helpers";

export default function MobileNavBar({ isOpenMenu, setIsOpenMenu, isDark }) {
  return (
    <nav className="flex justify-between items-center py-6 px-3 md:hidden min-h-[12dvh] drop-shadow-lg">
      <Link to="/" onClick={() => setIsOpenMenu(false)}>
        <img
          src={getImageUrl("logo.png", isDark)}
          alt="Logo"
          className="hover:cursor-pointer h-16"
        />
      </Link>
      <button onClick={() => setIsOpenMenu((prev) => !prev)}>
        <img
          src={getImageUrl(isOpenMenu ? "close.svg" : "menu.svg", isDark)}
          alt={`${isOpenMenu ? "Closing" : "Opening"} icon for responsive menu`}
          className="bg-primary-light dark:bg-primary-dark dark:border-dark h-10 p-1 duration-200 border rounded-md"
        />
      </button>
    </nav>
  );
}

MobileNavBar.propTypes = {
  isOpenMenu: PropTypes.bool.isRequired,
  setIsOpenMenu: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
};
