import { useState } from "react";

import aminoacids from "../data/aminoacids.json";
import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import ToolsSelector from "../components/toolbox/ToolSelector";
import PageHeader from "../components/ui/PageHeader";

function getAminoCount(sequence, amino) {
  return sequence.match(new RegExp(amino, "g"))
    ? `${amino} = ${sequence.match(new RegExp(amino, "g")).length}`
    : "";
}

export default function AminoacidCounter() {
  const [sequence, setSequence] = useState("");

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Amino Acid Counter">
        <ToolsSelector id={3} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-2">
          <label
            htmlFor="fasta"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your FASTA Sequence
          </label>
          <textarea
            value={sequence}
            onChange={(e) => {
              if (e.target.value.trim() !== "") {
                const data = e.target.value.toUpperCase().split("\n");
                const res = data.filter(
                  (t) => !t.match(/[^ACDEFGHIKLMNPQRSTVWY]/g)
                );
                if (res.length > 0) setSequence(res.join(""));
              } else if (e.target.value.trim() === "") setSequence("");
              else setSequence((prev) => prev);
            }}
            name="fasta"
            id="fasta"
            cols="30"
            rows="10"
            className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
            placeholder=">Sequence"
          />
        </section>
        <section className="space-y-2">
          <label
            htmlFor="length"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            Total Number of Molecules
          </label>
          <input
            className="!border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700"
            type="text"
            name="length"
            id="length"
            readOnly
            disabled
            placeholder="# of Molecules"
            value={sequence.length || ""}
          />
        </section>
        <section className="space-y-2">
          <h3 className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            Amino Acids
          </h3>
          <div className="grid grid-cols-4 gap-4">
            {aminoacids.map((amino) => {
              return (
                <input
                  className="md:col-span-1 col-span-2 text-center !border-light dark:!border-dark w-full p-2 rounded-lg bg-white dark:!bg-gray-800 text-primary-light dark:text-primary-dark border outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-default disabled:!bg-gray-200 dark:disabled:!bg-gray-700"
                  readOnly
                  disabled
                  name={amino}
                  id={amino}
                  key={amino}
                  placeholder={`# of ${amino}'s`}
                  value={getAminoCount(sequence, amino)}
                />
              );
            })}
          </div>
        </section>
      </ContentLayout>
    </MainLayout>
  );
}
