import PropTypes from "prop-types";

import { formatDate } from "../../../utils/helpers";

export default function UpdatedAt({ date }) {
  return (
    <p className="text-secondary-light dark:text-secondary-dark text-lg">
      <span className="font-semibold">Last updated:</span>{" "}
      {(date && formatDate(date)) || "Project has not been updated"}
    </p>
  );
}

UpdatedAt.propTypes = {
  date: PropTypes.string.isRequired,
};
