import PropTypes from "prop-types";

import { useState } from "react";

import HeaderMenuButton from "../ui/HeaderMenuButton";
import tools from "../../data/tools.json";

import ToolMenu from "./ToolMenu";

export default function ToolsSelector({ id }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-1/2 md:w-auto relative">
      <HeaderMenuButton setIsOpen={setIsOpen} image="tool" width="w-full">
        Tools
      </HeaderMenuButton>

      <ToolMenu tools={tools} id={id} open={isOpen} />
    </div>
  );
}

ToolsSelector.propTypes = {
  id: PropTypes.number,
};
