export default function NextButton({
  currentPage,
  totalPages,
  handlePageChange,
}) {
  return (
    <button
      className="dark:bg-secondary-btn-dark bg-secondary-btn-light enabled:hover:bg-secondary-btn-hover enabled:dark:hover:bg-secondary-btn-hoverDark text-secondary-light dark:text-secondary-dark md:w-1/6 disabled:cursor-not-allowed disabled:bg-opacity-70 w-1/3 px-4 py-2 font-semibold duration-200 rounded-lg"
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages}>
      Next
    </button>
  );
}
