import { useEffect, useContext, useState } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import toast from "react-hot-toast";

import axios from "axios";

import { GlobalContext } from "../contexts/GlobalContext";
import { API_URL } from "../utils/api";

export default function useRetrievePortfolios() {
  const { state, dispatch } = useContext(GlobalContext);
  const [portfolios, setPortfolios] = useState([]);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project");

  const navigate = useNavigate();

  useEffect(() => {
    const getPortfolios = async () => {
      toast.dismiss();
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await axios.get(
          `${API_URL}/pdf_docs/get_portfolios?project_id=${projectId}`
        );
        if (response.status !== 200) throw response;
        else if (response.data?.error === "Project does not belong to user.") {
          response.data.detail = "This portfolio does not exist.";
          throw response;
        } else {
          setPortfolios(response.data);
        }
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while retrieving portfolio, please try again."
            );
        navigate(`/projects?project=${projectId}`);
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };
    getPortfolios();
  }, [navigate, dispatch, projectId]);

  return {
    loadingInformation: state.loadingInformation,
    portfolios,
    setPortfolios,
  };
}
