import PropTypes from "prop-types";

import ToolLink from "./ToolLink";

export default function ToolMenu({ tools, id = 3, open = false }) {
  return (
    <menu
      className={`top-14 rounded-xl w-[200%] sm:w-[125%] md:w-[300%] p-6 absolute right-0 bg-tertiary-light dark:bg-tertiary-dark shadow-lg shadow-light dark:shadow-dark ${
        open ? "visible opacity-100" : "opacity-0 invisible"
      } transition-all duration-300`}>
      <h3 className="text-2xl font-semibold text-center mb-4 text-secondary-light dark:text-secondary-dark">
        Available Tools
      </h3>
      <ul className="flex flex-col space-y-4">
        {tools
          .filter((tool) => tool.enabled && tool.id !== id)
          .map((tool) => (
            <ToolLink tool={tool} key={tool.id} open={open} />
          ))}
      </ul>
    </menu>
  );
}

ToolMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  tools: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      link: PropTypes.string.isRequired,
    })
  ),
  id: PropTypes.number,
};
