import PropTypes from "prop-types";
import { getImageUrl } from "../../utils/helpers";
import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function FormHeader({ content }) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  return (
    <header className="flex flex-col items-center justify-center w-full space-y-8">
      <img src={getImageUrl("logo.png", isDark)} className="h-20" alt="logo" />
      <p className="text-primary-light dark:text-primary-dark text-2xl font-semibold text-center">
        {content}
      </p>
    </header>
  );
}

FormHeader.propTypes = {
  content: PropTypes.string.isRequired,
};
