import { useRef, useEffect, useContext } from "react";

import * as NGL from "ngl";

import { GlobalContext } from "../contexts/GlobalContext";

export default function useInitializeStage() {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;
  const stageRef = useRef(null);

  useEffect(() => {
    if (!stageRef.current) {
      const stage = new NGL.Stage("viewport", {
        backgroundColor: isDark ? "#1f2937" : "#e5e7eb",
      });
      stageRef.current = stage;
    } else {
      stageRef.current.setParameters({
        backgroundColor: isDark ? "#1f2937" : "#e5e7eb",
      });
    }
  }, [isDark]);

  return stageRef;
}
