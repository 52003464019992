import { useScrollIntoView } from "../../hooks/useScrollIntoView";

export default function NoSimilarity() {
  const ref = useScrollIntoView();

  return (
    <>
      <div ref={ref} />
      <p className="text-center font-semibold text-xl">
        No significant similarity found.
      </p>
    </>
  );
}
