import PropTypes from "prop-types";

export default function Tools({ children }) {
  return (
    <ul className="lg:grid-cols-3 md:grid-cols-2 grid gap-8">{children}</ul>
  );
}

Tools.propTypes = {
  children: PropTypes.node.isRequired,
};
