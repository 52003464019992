import PropTypes from "prop-types";

import { useContext } from "react";

import { GlobalContext } from "../../contexts/GlobalContext";
import { getImageUrl } from "../../utils/helpers";

export default function SettingsThemeButton({
  isDark,
  displayOptions,
  setLogoutDisplayOptions,
  setThemeDisplayOptions,
}) {
  const { dispatch } = useContext(GlobalContext);

  function handleClick() {
    setThemeDisplayOptions(false);

    dispatch({ type: "TOGGLE_THEME", payload: !isDark });

    if (isDark) {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.add("dark");
    }
  }

  return displayOptions ? (
    <div className="flex mx-auto space-x-2">
      <button
        disabled={!isDark}
        onClick={() => handleClick()}
        className={`enabled:hover:bg-primary-btn-light enabled:dark:hover:bg-primary-btn-dark dark:text-primary-dark text-primary-light disabled:text-primary-dark enabled:hover:text-primary-dark flex items-center justify-center px-4 py-2 mx-auto space-x-2 text-center transition-all duration-200 rounded-md disabled:cursor-not-allowed disabled:bg-primary-btn-light dark:disabled:bg-primary-btn-dark`}>
        <img
          src={getImageUrl("sun.svg", isDark)}
          alt="Button to change theme to light mode"
          className="w-5 h-5"
        />
        <span>Light</span>
      </button>
      <button
        disabled={isDark}
        onClick={() => handleClick()}
        className={`enabled:hover:bg-primary-btn-light enabled:dark:hover:bg-primary-btn-dark dark:text-primary-dark text-primary-light disabled:text-primary-dark enabled:hover:text-primary-dark flex items-center justify-center px-4 py-2 mx-auto space-x-2 text-center transition-all duration-200 rounded-md disabled:cursor-not-allowed disabled:bg-primary-btn-light dark:disabled:bg-primary-btn-dark`}>
        <img
          src={getImageUrl("moon.svg", isDark)}
          alt="Button to change theme to dark mode"
          className="w-5 h-5"
        />
        <span>Dark</span>
      </button>
    </div>
  ) : (
    <button
      onClick={() => {
        setThemeDisplayOptions(true);
        setLogoutDisplayOptions(false);
      }}
      className="hover:bg-primary-btn-light dark:hover:bg-primary-btn-dark dark:text-primary-dark text-primary-light disabled:text-primary-dark enabled:hover:text-primary-dark hover:py-2 flex items-center justify-center px-4 mx-auto space-x-2 text-center transition-all duration-200 rounded-md">
      <img
        src={getImageUrl(isDark ? "moon.svg" : "sun.svg", isDark)}
        alt="Display current theme and open theme selection options"
        className="w-5 h-5"
      />
      <span>Theme</span>
    </button>
  );
}

SettingsThemeButton.propTypes = {
  isDark: PropTypes.bool.isRequired,
  displayOptions: PropTypes.bool.isRequired,
  setLogoutDisplayOptions: PropTypes.func.isRequired,
  setThemeDisplayOptions: PropTypes.func.isRequired,
};
