import { useState } from "react";

import toast from "react-hot-toast";

import codonTable from "../data/codon_table.json";
import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import ToolsSelector from "../components/toolbox/ToolSelector";
import PageHeader from "../components/ui/PageHeader";

const initialState = {
  value: "",
  error: "",
  canClick: false,
  valid: false,
};

function translateToAminoAcids(sequence) {
  const aminoAcids = sequence.map((triplet) => {
    return codonTable[triplet];
  });
  return aminoAcids;
}

export default function NucleotideToAminoacid() {
  const [data, setData] = useState(initialState);

  return (
    <MainLayout>
      <PageHeader to="/tools" title="Nucleotide to Amino Acid Translator">
        <ToolsSelector id={7} />
      </PageHeader>
      <ContentLayout>
        <section className="space-y-4">
          <label
            htmlFor="sequence"
            className="text-secondary-light dark:text-secondary-dark text-2xl font-semibold">
            1. Insert your Nucleotide Sequence that will be translated to Amino
            Acids
          </label>
          <textarea
            value={data.value}
            onChange={(e) => {
              if (e.target.value.trim() === "") {
                setData(initialState);
                return;
              }
              if (e.target.value.trim() !== "") {
                const data = e.target.value.toUpperCase().split("\n");
                const res = data.filter((t) => !t.match(/[^ATUCG]/g));
                if (res.length > 0)
                  setData({
                    value: res.join("").replace(/T/g, "U"),
                    error: "",
                    canClick: true,
                  });
              } else
                setData((prev) => ({
                  ...prev,
                  error: "",
                  canClick: false,
                  valid: false,
                }));
            }}
            name="sequence"
            id="sequence"
            cols="30"
            rows="10"
            className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
            placeholder=">Sequence"
          />
          <p className="text-sm text-tertiary-light dark:text-tertiary-dark">
            * T nucleotides will be changed to U nucleotides
          </p>
          {data.error && <p className="text-red-500 text-lg">{data.error}</p>}
        </section>
        <button
          disabled={!data.canClick}
          onClick={() => {
            const valueNoSpaces = data.value.replace(/\s/g, "");
            if (valueNoSpaces.length % 3 !== 0) {
              setData((prev) => ({
                ...prev,
                error: `The sequence must be a multiple of 3, you're missing ${
                  3 - (valueNoSpaces.length % 3)
                } characters`,
                canClick: false,
                valid: false,
              }));
              return;
            } else {
              setData((prev) => ({
                ...prev,
                error: "",
                canClick: true,
                valid: true,
              }));
            }
          }}
          className="bg-primary-btn-light dark:bg-primary-btn-dark text-white rounded-lg enabled:hover:bg-primary-btn-hover enabled:dark:hover:bg-primary-btn-hoverDark duration-200 w-full disabled:cursor-not-allowed disabled:bg-opacity-70 px-4 py-2 flex justify-center items-center">
          Translate
        </button>

        {data.valid && (
          <div className="w-full border p-4 rounded-xl shadow-lg shadow-light dark:shadow-dark space-y-4 border-light dark:border-dark">
            <div className="flex items-center justify-between space-x-2">
              <h3 className="text-2xl md:text-3xl font-bold text-primary-light dark:text-primary-dark">
                Amino Acids from sequence
              </h3>
              <button
                onClick={async () => {
                  toast.dismiss();
                  try {
                    await navigator.clipboard.writeText(
                      translateToAminoAcids(data.value.match(/.{1,3}/g)).join(
                        ""
                      )
                    );
                    toast.success("Sequence copied to clipboard!");
                  } catch (error) {
                    toast.error("Failed to copy!");
                  }
                }}>
                <img
                  className="h-8 w-8 duration-200 rounded-md bg-primary-btn-light dark:bg-primary-btn-dark hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark p-1"
                  src={
                    "https://storage.googleapis.com/gpp4-bucket/src/light-copy.svg"
                  }
                  alt="clipboard icon to copy cut sequence"
                />
              </button>
            </div>
            <p className="mono-font break-words text-wrap text-secondary-light dark:text-secondary-dark">
              {translateToAminoAcids(data.value.match(/.{1,3}/g)).join("")}
            </p>
          </div>
        )}
      </ContentLayout>
    </MainLayout>
  );
}
