import { useState } from "react";

import axios from "axios";

import toast from "react-hot-toast";

import ErrorMessage from "../ui/ErrorMessage";
import PageHeader from "../ui/PageHeader";
import PaginatedTable from "../ui/PaginatedTable";
import Spacer from "../ui/Spacer";
import useValidateProject from "../../hooks/useValidateProject";
import ContentLayout from "../../layouts/ContentLayout";
import { API_URL } from "../../utils/api";
import OrganismInformation from "../module3/ui/OrganismInformation";
import SearchButton from "../module3/ui/SearchButton";
import SearchForm from "../module3/ui/SearchForm";
import SearchInput from "../module3/ui/SearchInput";
import ModuleSelectorButton from "../project_details/ui/ModuleSelectorButton";

export default function Module3() {
  const { id: projectId, loadingInformation } = useValidateProject();
  const [enzyme, setEnzyme] = useState({
    id: -1,
    value: "",
    error: "",
  });
  const [results, setResults] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const enzymeId = parseInt(enzyme.value.split(" - ")[0]) || null;
    if (enzyme.value.trim() === "" || !enzymeId) {
      setEnzyme({ ...enzyme, error: "Please select a valid enzyme name" });
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.get(
        `${API_URL}/brenda/V2/get_organisms?rn_id=${enzymeId}`
      );
      if (response.status !== 200) throw response;
      setResults(response.data);
    } catch (error) {
      toast.dismiss();
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error("An error occurred while searching for the enzyme.");
    }
    setIsLoading(false);
  }

  return (
    <>
      {!loadingInformation && (
        <PageHeader to={`/projects?project=${projectId}`} title="Module 3">
          <ModuleSelectorButton projectId={projectId} moduleId={3} />
        </PageHeader>
      )}
      <ContentLayout>
        <SearchForm
          labelValue={"Enzyme name"}
          identifier={"enzyme"}
          handleSubmit={handleSubmit}
          error={enzyme.error}>
          <SearchInput
            identifier={"enzyme"}
            placeholder={"Target enzyme"}
            value={enzyme.value}
            setValue={setEnzyme}
            disabled={isLoading}
            show={true}
            double={false}
          />
          <ErrorMessage error={enzyme.error} />
          <SearchButton
            disabled={isLoading || results?.results?.length > 0}
            isLoading={isLoading}
          />
        </SearchForm>

        <Spacer />

        {results?.results?.length > 0 && (
          <>
            <OrganismInformation results={results} />
            <PaginatedTable items={results?.results} />
          </>
        )}
      </ContentLayout>
    </>
  );
}
