import PropTypes from "prop-types";

export default function Note({ children }) {
  return (
    <p className="bg-secondary-light dark:bg-secondary-dark text-balance text-secondary-light dark:text-secondary-dark border-dark dark:!border-light shadow-light dark:shadow-dark p-2 italic text-center border rounded-md shadow-sm text-lg">
      <span className="font-bold tracking-wide">Note: </span>
      {children}
    </p>
  );
}

Note.propTypes = {
  children: PropTypes.node.isRequired,
};
