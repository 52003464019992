import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";

import { API_URL } from "../utils/api";
import CustomLink from "../components/ui/CustomLink";
import ErrorMessage from "../components/ui/ErrorMessage";
import FormHeader from "../components/ui/FormHeader";
import InputContainer from "../components/ui/InputContainer";
import InputField from "../components/ui/InputField";
import Note from "../components/ui/Note";
import SubmitButton from "../components/ui/SubmitButton";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function validateData() {
    let isValid = true;
    setEmailError("");
    if (email.trim() === "") {
      setEmailError("Email must not be empty");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Please validate email format");
      isValid = false;
    }
    return isValid;
  }

  const handleSubmit = async (e) => {
    toast.dismiss();
    e.preventDefault();
    if (validateData()) {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/users/register_client`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, notes }),
          timeout: 30000,
        });

        if (response.status !== 201) throw response;

        toast.success(`A verification email has been sent to ${email}`);
        navigate("/login");
      } catch (error) {
        const data = await error.json();
        toast.dismiss();
        data?.detail
          ? toast.error(`${data.detail}`)
          : toast.error("An error occurred while signing up");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <section className="bg-primary-light dark:bg-primary-dark min-h-dvh flex items-center justify-center px-4 py-8">
      <form
        onSubmit={handleSubmit}
        className="bg-secondary-light dark:bg-secondary-dark rounded-xl md:max-w-2xl w-full px-4 py-6 space-y-8 shadow-2xl">
        <FormHeader content={"Sign Up"} />
        <Note>
          After entering your email address, you will recieve a confirmation
          code on that same email address to verify your identity.
        </Note>
        <InputContainer>
          <InputField
            value={email}
            error={emailError}
            setValue={setEmail}
            resetError={() => setEmailError("")}
            id="email"
            required
          />
          <ErrorMessage error={emailError} />
        </InputContainer>

        <InputContainer>
          <label className="dark:text-gray-100 font-semibold" htmlFor="notes">
            Notes
          </label>
          <textarea
            name="notes"
            id="notes"
            className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
            placeholder="Notes that may need to be added..."
            value={notes}
            onChange={({ target }) => setNotes(target.value)}
          />
        </InputContainer>

        <div className="flex flex-col items-center justify-center space-y-4">
          <SubmitButton loading={loading} disabled={loading}>
            Sign Up
          </SubmitButton>
          <CustomLink to="/login" partialText="Already have an account?">
            Log in
          </CustomLink>
          <p className="dark:text-gray-300 text-xs text-center">
            * Required field
          </p>
        </div>
      </form>
    </section>
  );
}
