export default function Overview() {
  return (
    <aside className="md:flex xl:w-1/3 bg-gradient-to-br from-custom-blue to-custom-pink rounded-r-2xl flex-col justify-center hidden w-1/2 px-8 space-y-8 shadow-2xl">
      <h4 className="text-primary-dark text-4xl font-bold text-center">
        We are more than just a company
      </h4>
      <p className="text-primary-dark text-xl font-light text-justify">
        At the epicenter of bioinformatic innovation, we extend a warm welcome
        to The Gene Paradox Platform. Here, science meets technology to unlock
        the secrets of the genetic code and open new frontiers in
        biotechnological research.
      </p>
    </aside>
  );
}
