export default function SearchButton({ isLoading, disabled = false }) {
  return (
    <button
      type="submit"
      disabled={disabled}
      className="bg-primary-btn-light dark:bg-primary-btn-dark enabled:hover:bg-primary-btn-hover enabled:dark:hover:bg-primary-btn-hoverDark disabled:cursor-not-allowed disabled:bg-opacity-70 flex items-center justify-center w-full px-4 py-2 text-white duration-200 rounded-lg">
      {isLoading ? (
        <img
          className="w-7 h-7 mx-auto"
          src="https://storage.googleapis.com/gpp4-bucket/src/light-loader.svg"
          alt="loading svg"
        />
      ) : (
        "Search"
      )}
    </button>
  );
}
