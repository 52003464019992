import PropTypes from "prop-types";

export default function SubmitButton({
  disabled = false,
  children,
  loading = false,
}) {
  return (
    <button
      type="submit"
      className="enabled:hover:bg-blue-600 dark:bg-blue-600 dark:enabled:hover:bg-blue-700 rounded-xl disabled:cursor-not-allowed disabled:bg-opacity-70 hover:bg-blue-600 sm:w-fit w-full px-4 py-2 text-lg font-semibold text-white duration-200 bg-blue-500"
      disabled={disabled}>
      {loading ? (
        <img
          className="w-7 h-7 mx-auto"
          src="https://storage.googleapis.com/gpp4-bucket/src/light-loader.svg"
          alt="Loading state on button"
        />
      ) : (
        children
      )}
    </button>
  );
}

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};
