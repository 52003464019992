import PropTypes from "prop-types";

import { formatDate } from "../../../utils/helpers";

export default function CreatedAt({ date }) {
  return (
    <p className="text-lg text-secondary-light dark:text-secondary-dark">
      <span className="font-semibold">Created:</span> {formatDate(date)}
    </p>
  );
}

CreatedAt.propTypes = {
  date: PropTypes.string.isRequired,
};
