import PropTypes from "prop-types";

export default function Name({ name }) {
  return (
    <h3 className="md:text-left text-primary-light dark:text-primary-dark text-3xl font-bold text-center">
      {name}
    </h3>
  );
}

Name.propTypes = {
  name: PropTypes.string.isRequired,
};
