import PropTypes from "prop-types";
import CreateNewProjectForm from "./CreateNewProjectForm";
import Project from "./Project";

export default function Projects({
  projects,
  setProjects,
  handleClick,
  isFormOpen = false,
}) {
  return (
    <section className="sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 grid grid-cols-1 gap-4">
      {isFormOpen ? (
        <article className="bg-secondary-light dark:bg-secondary-dark dark:border-dark border-light relative flex flex-col items-center justify-center p-4 space-y-4 text-center border rounded-lg shadow-md">
          <CreateNewProjectForm
            closeForm={() => handleClick(false)}
            setProjects={setProjects}
          />
        </article>
      ) : (
        <button
          onClick={() => handleClick(true)}
          className="rounded-2xl hover:shadow-xl bg-primary-btn-light dark:bg-primary-btn-dark hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark shadow-light dark:shadow-dark flex flex-col items-center justify-center p-10 space-y-4 text-xl text-center duration-300 shadow-md cursor-pointer">
          <p className="text-[7rem] text-primary-dark">+</p>
          <p className="text-primary-dark text-lg">Create New Project</p>
        </button>
      )}
      {projects.map((project) => (
        <Project key={project.project_id} project={project} />
      ))}
    </section>
  );
}

Projects.propTypes = {
  projects: PropTypes.array.isRequired,
  setProjects: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  isFormOpen: PropTypes.bool,
};
