import { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";

import ReCAPTCHA from "react-google-recaptcha";

import { validatePassword } from "../helpers/validation";
import { API_URL } from "../utils/api";
import ErrorMessage from "../components/ui/ErrorMessage";
import FormHeader from "../components/ui/FormHeader";
import InputContainer from "../components/ui/InputContainer";
import InputField from "../components/ui/InputField";
import SubmitButton from "../components/ui/SubmitButton";

export default function ConfirmEmail() {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setUser(JSON.parse(sessionStorage.getItem("user")));
    } else {
      navigate("/");
      toast.error("The page you are trying to access is not available");
    }
  }, [navigate]);

  // input variables
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [industry, setIndustry] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [recaptcha, setRecaptcha] = useState("");
  const captcha = useRef(null);

  // error variables
  const [passwordErrors, setPasswordErrors] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [industryError, setIndustryError] = useState("");
  const [privacyPolicyError, setPrivacyPolicyError] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");

  function validateData() {
    let isValid = true;
    // reset Errors
    setPasswordErrors([]);
    setNameError("");
    setPhoneError("");
    setCompanyError("");
    setIndustryError("");
    setPrivacyPolicyError("");

    if (name.trim() === "") {
      isValid = false;
      setNameError("Name must not be empty");
    }
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(phone)) {
      isValid = false;
      setPhoneError("Phone number must have 10 digits");
    }
    if (company.trim() === "") {
      isValid = false;
      setCompanyError("Company must not be empty");
    }
    if (industry.trim() === "") {
      isValid = false;
      setIndustryError("Industry must not be empty");
    }

    const password_errors = validatePassword(password);
    setPasswordErrors(password_errors);
    if (password_errors.length > 0) {
      isValid = false;
    }

    if (!privacyPolicy) {
      isValid = false;
      setPrivacyPolicyError(
        "You must agree with the privacy policy in order to use our services"
      );
    }
    if (!recaptcha) {
      isValid = false;
      setRecaptchaError("Please verify with Captcha");
    }
    return isValid;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();

    if (validateData()) {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_URL}/users/confirm/${user.confirm_token}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: user.email,
              password,
              name,
              phone,
              company,
              industry,
            }),
            timeout: 30000,
          }
        );

        if (response.status !== 200) throw response;

        toast.success("Account created successfully!");
        sessionStorage.removeItem("user");
        navigate("/login");
      } catch (error) {
        const data = await error.json();
        toast.dismiss();
        data?.detail
          ? toast.error(`${data.detail}`)
          : toast.error("An error occurred while creating the account");
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <section className="bg-primary-light dark:bg-primary-dark min-h-dvh flex items-center justify-center px-4 py-8">
      <form
        onSubmit={handleSubmit}
        className="bg-secondary-light dark:bg-secondary-dark rounded-xl md:max-w-2xl w-full px-4 py-6 space-y-8 shadow-2xl">
        <FormHeader content={"Finish setting up your account"} />

        <InputContainer>
          <InputField value={user?.email} id="email" readOnly disabled />
        </InputContainer>

        <InputContainer>
          <InputField
            value={name}
            error={nameError}
            resetError={() => setNameError("")}
            id="name"
            setValue={setName}
            required
          />
          <ErrorMessage error={nameError} />
        </InputContainer>

        <InputContainer>
          <InputField
            value={password}
            error={passwordErrors.length > 0}
            resetError={() => setPasswordErrors([])}
            id="password"
            setValue={setPassword}
            required
            type="password"
          />
          {passwordErrors.length > 0 && <ErrorMessage error={passwordErrors} />}
        </InputContainer>

        <InputContainer>
          <InputField
            value={phone}
            error={phoneError}
            resetError={() => setPhoneError("")}
            id="phoneNumber"
            setValue={setPhone}
            required
            type="tel"
          />
          {phoneError && <ErrorMessage error={phoneError} />}
        </InputContainer>

        <InputContainer>
          <InputField
            value={company}
            error={companyError}
            resetError={() => setCompanyError("")}
            id="company"
            setValue={setCompany}
            required
          />
          {companyError && <ErrorMessage error={companyError} />}
        </InputContainer>

        <InputContainer>
          <InputField
            value={industry}
            error={industryError}
            resetError={() => setIndustryError("")}
            id="industry"
            setValue={setIndustry}
            required
          />
          {industryError && <ErrorMessage error={industryError} />}
        </InputContainer>

        <div className="space-y-1">
          <div className="inline-flex items-center justify-center space-x-2">
            <input
              className={`${
                privacyPolicyError && !privacyPolicy && "!border-red-500"
              } w-4 h-4 rounded-sm bg-gray-100 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 hover:bg-gray-50 dark:hover:bg-gray-700 duration-200 disabled:cursor-not-allowed disabled:!bg-gray-200 dark:disabled:!bg-gray-700`}
              type="checkbox"
              id="privacyPolicy"
              checked={privacyPolicy}
              onChange={() => {
                setPrivacyPolicy((prev) => !prev);
                setPrivacyPolicyError("");
              }}
            />
            <label className="dark:text-gray-100" htmlFor="privacyPolicy">
              I have read and agree to the{" "}
              <a
                className="hover:underline hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-500 cursor-pointe text-blue-500 duration-200"
                href="https://www.geneparadox.com/privacy_policy.pdf"
                target="_blank"
                rel="noreferrer">
                privacy policy
              </a>
            </label>
          </div>
          {privacyPolicyError && !privacyPolicy && (
            <ErrorMessage error={privacyPolicyError} />
          )}
        </div>

        <div className="flex flex-col items-center">
          <ReCAPTCHA
            ref={captcha}
            sitekey="6Lf-NbQoAAAAAMLiV3v5uoIcIyEBXo-ptrDfWtV2"
            onChange={() => {
              setRecaptcha(captcha.current.getValue());
              setRecaptchaError("");
            }}
          />
          {recaptchaError && (
            <span className="text-xs text-red-600">{recaptchaError}</span>
          )}
        </div>

        <div className="flex flex-col items-center space-y-2">
          <SubmitButton disabled={loading} loading={loading}>
            Complete registration
          </SubmitButton>
          <p className="dark:text-gray-300 text-xs text-center">
            * Required field
          </p>
        </div>
      </form>
    </section>
  );
}
