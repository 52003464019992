import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  formatProjectCreationDate,
  formatProjectUpdateDate,
} from "../../utils/helpers";

export default function Project({ project }) {
  const { name, notes, createdAt, updatedAt, project_id: id } = project;

  return (
    <Link
      to={`/projects?project=${id}`}
      className="rounded-2xl hover:shadow-inner dark:hover:shadow-secondary-dark hover:shadow-secondary-light bg-secondary-light dark:bg-secondary-dark border-light dark:border-dark dark:shadow-secondary-dark shadow-secondary-light group flex flex-col justify-between p-6 space-y-4 duration-300 border shadow">
      <h2 className="text-link-light dark:text-link-dark group-hover:text-link-hover dark:group-hover:text-link-hoverDark group-hover:underline text-2xl font-bold duration-200">
        {name}
      </h2>
      <p className="!text-ellipsis text-start text-secondary-light dark:text-secondary-dark">
        {/* hacer que las notas tengan una longitud máxima de 100 caracateres y si sobran, poner puntos suspensivos */}
        <span className="text-tertiary-light dark:text-tertiary-dark font-semibold">
          Notes:
        </span>{" "}
        {notes && notes.length > 100 ? `${notes.slice(0, 100)}...` : notes}
      </p>
      <div className="text-secondary-light dark:text-secondary-dark flex flex-col items-start space-y-1 text-sm">
        <p>
          <span className="text-tertiary-light dark:text-tertiary-dark font-semibold">
            Created on:
          </span>{" "}
          {formatProjectCreationDate(createdAt)}
        </p>
        <p>
          <strong className="text-tertiary-light dark:text-tertiary-dark font-semibold">
            Last updated:
          </strong>{" "}
          {formatProjectUpdateDate(updatedAt)}
        </p>
      </div>
    </Link>
  );
}

Project.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
    notes: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
    project_id: PropTypes.number.isRequired,
  }).isRequired,
};
