import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";

import { API_URL } from "../utils/api";
import CustomLink from "../components/ui/CustomLink";
import ErrorMessage from "../components/ui/ErrorMessage";
import FormHeader from "../components/ui/FormHeader";
import InputContainer from "../components/ui/InputContainer";
import InputField from "../components/ui/InputField";
import Note from "../components/ui/Note";
import SubmitButton from "../components/ui/SubmitButton";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  function validateData() {
    let isValid = true;
    setEmailError("");
    if (email.trim() === "") {
      setEmailError("Email must not be empty");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Please validate email format");
      isValid = false;
    }
    return isValid;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();
    if (validateData()) {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/users/forgot_password`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(email.trim()),
          timeout: 30000,
        });

        if (response.status !== 200) throw response;
        toast.success(`An email has been sent to: ${email}`);
        navigate("/login");
      } catch (error) {
        const data = await error.json();
        toast.dismiss();
        data?.detail
          ? toast.error(`${data.detail}`)
          : toast.error("An error occurred while sending email");
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <section className="bg-primary-light dark:bg-primary-dark min-h-dvh flex items-center justify-center px-4 py-8">
      <form
        onSubmit={handleSubmit}
        className="bg-secondary-light dark:bg-secondary-dark rounded-xl md:max-w-2xl w-full px-4 py-6 space-y-8 shadow-2xl">
        <CustomLink to="/login" partialText="&larr;">
          Go Back to Login
        </CustomLink>
        <FormHeader content={"Password Recovery"} />
        <Note>
          After entering your email address, you will recieve a code on that
          same email address to change your password.
        </Note>

        <InputContainer>
          <InputField
            type="email"
            id="email"
            value={email}
            error={emailError}
            resetError={() => setEmailError("")}
            setValue={setEmail}
            required
          />
          <ErrorMessage error={emailError} />
        </InputContainer>

        <div className="flex flex-col items-center justify-center space-y-4">
          <SubmitButton loading={loading} text="Send Email" disabled={loading}>
            Send Email
          </SubmitButton>
          <p className="dark:text-secondary-dark text-secondary-light text-xs text-center">
            * Required field
          </p>
        </div>
      </form>
    </section>
  );
}
