export default function AutocompleteOptions({
  isLoading,
  options,
  setValue,
  show,
}) {
  return (
    <div
      className={`w-full bg-secondary-light dark:bg-secondary-dark rounded-lg shadow-md ${
        !show && "absolute"
      }`}>
      {isLoading && (
        <div className="text-secondary-light dark:text-secondary-dark p-2 text-center cursor-default">
          Loading...
        </div>
      )}
      {options.length > 0 &&
        !isLoading &&
        options.map((option, idx) => (
          <button
            onMouseDown={() => setValue({ value: option, error: "" })}
            key={`${option}_${idx}`}
            className="hover:bg-tertiary-light dark:hover:bg-tertiary-dark text-tertiary-light dark:text-tertiary-dark w-full p-2 text-left cursor-pointer">
            {option}
          </button>
        ))}
      {options.length === 0 && !isLoading && (
        <div className="text-secondary-light dark:text-secondary-dark p-2 text-center cursor-default">
          No options available
        </div>
      )}
    </div>
  );
}
