import { useState } from "react";

import organisms from "../data/gras.json";
import ContentLayout from "../layouts/ContentLayout";
import MainLayout from "../layouts/MainLayout";
import ToolsSelector from "../components/toolbox/ToolSelector";
import PageHeader from "../components/ui/PageHeader";

export default function GRASOrganism() {
  const [filterGRN, setFilterGRN] = useState("");
  const [filterSubstance, setFilterSubstance] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredOrganisms = organisms.filter(
    (organism) =>
      organism.GRAS.includes(filterGRN) &&
      organism.Substance.toLowerCase().includes(filterSubstance.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOrganisms.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredOrganisms.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <MainLayout>
      <PageHeader to="/tools" title="GRAS Organism List">
        <ToolsSelector id={9} />
      </PageHeader>
      <ContentLayout>
        <div className="space-y-4 w-full lg:flex lg:space-y-0 lg:space-x-10 lg:justify-between">
          <div className="space-y-2 w-full">
            <label
              className="text-secondary-light dark:text-secondary-dark text-lg font-semibold"
              htmlFor="filterGRN">
              Filter by GRN
            </label>
            <input
              id="filterGRN"
              name="filterGRN"
              type="text"
              placeholder="Enter GRN Number"
              value={filterGRN}
              onChange={(e) => {
                setFilterGRN(e.target.value);
                setCurrentPage(1);
              }}
              className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
            />
          </div>
          <div className="space-y-2 w-full">
            <label
              className="text-secondary-light dark:text-secondary-dark text-lg font-semibold"
              htmlFor="filterSubstance">
              Filter by Substance
            </label>
            <input
              id="filterSubstance"
              name="filterSubstance"
              type="text"
              placeholder="Enter Substance"
              value={filterSubstance}
              onChange={(e) => {
                setFilterSubstance(e.target.value);
                setCurrentPage(1);
              }}
              className="w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none dark:focus:!border-blue-400 focus:!border-blue-500 placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark"
            />
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <button
            className="dark:bg-secondary-btn-dark bg-secondary-btn-light enabled:hover:bg-secondary-btn-hover enabled:dark:hover:bg-secondary-btn-hoverDark text-secondary-light dark:text-secondary-dark md:w-1/6 disabled:cursor-not-allowed disabled:bg-opacity-70 w-1/3 px-4 py-2 font-semibold duration-200 rounded-lg"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}>
            Previous
          </button>
          <span className="text-tertiary-light dark:text-tertiary-dark">
            {currentPage} of {totalPages}
          </span>
          <button
            className="dark:bg-secondary-btn-dark bg-secondary-btn-light enabled:hover:bg-secondary-btn-hover enabled:dark:hover:bg-secondary-btn-hoverDark text-secondary-light dark:text-secondary-dark md:w-1/6 disabled:cursor-not-allowed disabled:bg-opacity-70 w-1/3 px-4 py-2 font-semibold duration-200 rounded-lg"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}>
            Next
          </button>
        </div>

        <section className="space-y-4 w-full overflow-scroll">
          <table className="w-full border border-light dark:border-dark">
            <thead className="text-primary-light dark:text-primary-dark">
              <tr>
                <th className="border border-light dark:border-dark p-2 dark:bg-secondary-dark bg-secondary-light">
                  GRAS Notice (GRN) No.
                </th>
                <th className="border border-light dark:border-dark p-2 dark:bg-secondary-dark bg-secondary-light">
                  Substance
                </th>
                <th className="border border-light dark:border-dark p-2 dark:bg-secondary-dark bg-secondary-light">
                  Intended Use
                </th>
                <th className="border border-light dark:border-dark p-2 dark:bg-secondary-dark bg-secondary-light">
                  Basis
                </th>
                <th className="border border-light dark:border-dark p-2 dark:bg-secondary-dark bg-secondary-light">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(
                ({ GRAS, Substance, Use, Basis, Date: _Date }) => (
                  <tr key={GRAS}>
                    <td className="border border-light dark:border-dark p-2 text-balance bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark">
                      {GRAS}
                    </td>
                    <td className="border border-light dark:border-dark p-2 text-balance bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark">
                      {Substance}
                    </td>
                    <td className="border border-light dark:border-dark p-2 text-balance bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark">
                      {Use}
                    </td>
                    <td className="border border-light dark:border-dark p-2 text-balance bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark">
                      {Basis}
                    </td>
                    <td className="border border-light dark:border-dark p-2 text-balance bg-primary-light dark:bg-primary-dark text-secondary-light dark:text-secondary-dark">
                      {_Date}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </section>

        <section className="space-y-4">
          <p className="text-sm text-tertiary-light dark:text-tertiary-dark">
            Downloaded from FDA GRAS Notices: you can find the{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.cfsanappsexternal.fda.gov/scripts/fdcc/?set=GRASNotices"
              className="hover:underline hover:text-link-hover dark:hover:text-link-hoverDark text-link-light dark:text-link-dark text-sm duration-200">
              GRAS Organism List here
            </a>
            {}; Last updated 4/11/2024; downloaded 4/16/2024.
          </p>
        </section>
      </ContentLayout>
    </MainLayout>
  );
}
