import PropTypes from "prop-types";

export default function MainLayout({ children, relative = false }) {
  return (
    <main
      className={`w-full min-h-[64dvh] px-3 md:!px-8 md:py-12 py-6 bg-primary-light dark:bg-primary-dark ${
        relative && "relative"
      }`}>
      {children}
    </main>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  relative: PropTypes.bool,
};
