import PropTypes from "prop-types";

export default function ProjectNotesDisplay({ notes, setCanEditNotes }) {
  return (
    <div className="flex flex-row-reverse space-x-reverse space-x-6 md:flex-row md:space-x-6 items-start">
      <button className="mt-1" onClick={() => setCanEditNotes(true)}>
        <img
          className="h-8 duration-200 rounded-md bg-primary-btn-light dark:bg-primary-btn-dark hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark p-1"
          src={
            "https://storage.googleapis.com/gpp4-bucket/src/light-pencil.svg"
          }
          alt="Edit button to change project notes"
        />
      </button>
      <p className="dark:text-gray-200 text-xl text-gray-800 break-words text-wrap w-fit">
        <span className="font-semibold">Description:</span> {notes}
      </p>
    </div>
  );
}

ProjectNotesDisplay.propTypes = {
  notes: PropTypes.string.isRequired,
  setCanEditNotes: PropTypes.func.isRequired,
};
