import { useEffect, useContext } from "react";

import { GlobalContext } from "../contexts/GlobalContext";

const useTheme = () => {
  const { state, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const userPrefersDark = mediaQuery.matches;
    if (userPrefersDark) {
      dispatch({ type: "TOGGLE_THEME", payload: true });
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    mediaQuery.addEventListener("change", (e) => {
      if (e.matches) {
        dispatch({ type: "TOGGLE_THEME", payload: true });
        document.documentElement.classList.add("dark");
      } else {
        dispatch({ type: "TOGGLE_THEME", payload: false });
        document.documentElement.classList.remove("dark");
      }
    });

    return () => {
      mediaQuery.removeEventListener("change", () => {});
    };
  }, [dispatch]);

  return state.darkMode;
};

export default useTheme;
