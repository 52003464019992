import PropTypes from "prop-types";
import SubmitButton from "../ui/SubmitButton";
import CustomLink from "../ui/CustomLink";

export default function Footer({ loading = false }) {
  return (
    <div className="flex flex-col items-center justify-center space-y-4">
      <SubmitButton disabled={loading} loading={loading}>
        Log in
      </SubmitButton>
      <CustomLink to="/sign_up" partialText="Don't have an account?">
        Sign up
      </CustomLink>
    </div>
  );
}

Footer.propTypes = {
  loading: PropTypes.bool,
};
