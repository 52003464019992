import PropTypes from "prop-types";

import { useState, useContext, useEffect } from "react";

import toast from "react-hot-toast";

import { GlobalContext } from "../../contexts/GlobalContext";
import { updateNotes } from "../../utils/project-info/helpers";

import CancelButton from "./ui/CancelButton";
import SubmitButton from "./ui/SubmitButton";

export default function ProjectNotesForm({
  id,
  originalNotes, // the notes that were already in the database
  setProjectNotes, // function to update the notes in the parent component and not have to re-fetch them
  setCanEditNotes, // function to close this form
}) {
  const { state, dispatch } = useContext(GlobalContext);
  const { loadingInformation } = state;

  const [notes, setNotes] = useState(originalNotes);
  const [error, setError] = useState("");

  useEffect(() => {
    if (notes.length === 0) {
      // if notes are empty or have more characters than allowed, set the error
      setError("Notes cannot be empty");
    } else if (notes.length > 300) {
      setError("Notes must be 300 characters or less");
    } else {
      setError("");
    }
  }, [notes]);

  const setIsLoading = (value) => {
    // function to set global loading state
    dispatch({ type: "SET_LOADING", payload: value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    toast.dismiss(); // dismiss any existing toasts
    const { ok, message } = await updateNotes(
      id,
      notes,
      setIsLoading,
      setProjectNotes
    ); // await the API response
    // given that setIsLoading sets global loading state, the page will re-render so we need to know what the API response was so we can show the user the corresponding message
    ok ? toast.success(message) : toast.error(message);
    setCanEditNotes(false); // close the form
  }

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
      <div className="flex flex-col space-y-2 items-end">
        <textarea
          type="text"
          value={notes}
          onChange={({ target }) => setNotes(target.value)}
          className={`${
            error
              ? "!border-red-500"
              : "dark:focus:!border-blue-400 focus:!border-blue-500"
          } w-full p-2 rounded-lg bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border border-light dark:border-dark outline-none placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark`}
        />
        <div className="flex justify-between w-full">
          <span
            className={`text-red-500 ${
              error ? "opacity-100" : "opacity-0"
            } transition-opacity duration-200`}>
            {error}
          </span>
          <span
            className={`transition-colors duration-200 ${
              error
                ? "text-red-500"
                : "text-tertiary-light dark:text-tertiary-dark"
            }
            `}>
            {notes.length} / 300
          </span>
        </div>
      </div>
      <div className="flex space-x-8 justify-end">
        <SubmitButton
          disabled={loadingInformation || error || originalNotes === notes} // disable the button if the form is loading, there's an error, or the notes haven't changed
        />
        <CancelButton
          isLoading={loadingInformation}
          handleClick={() => setCanEditNotes(false)}
        />
      </div>
    </form>
  );
}

ProjectNotesForm.propTypes = {
  id: PropTypes.number.isRequired,
  originalNotes: PropTypes.string.isRequired,
  setProjectNotes: PropTypes.func.isRequired,
  setCanEditNotes: PropTypes.func.isRequired,
};
