import { Route, Routes } from "react-router-dom";

import ConfirmEmail from "../pages/ConfirmEmail";
import ForgotPassword from "../pages/ForgotPassword";
import Login from "../pages/Login";
import PropTypes from "prop-types";
import RestorePassword from "../pages/RestorePassword";
import SignUp from "../pages/SignUp";
import ValidateEmail from "../pages/ValidateEmail";

export default function NotLoggedInRoutes({ setUserInformation }) {
  return (
    <Routes>
      <Route
        path="*"
        element={<Login setUserInformation={setUserInformation} />}
      />
      <Route
        path="/"
        element={<Login setUserInformation={setUserInformation} />}
      />
      <Route
        path="/login"
        element={<Login setUserInformation={setUserInformation} />}
      />
      <Route path="/sign_up" element={<SignUp />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/confirm" element={<ConfirmEmail />} />
      <Route path="/restore_password/:token" element={<RestorePassword />} />
      <Route path="/confirm/:token" element={<ValidateEmail />} />
    </Routes>
  );
}

NotLoggedInRoutes.propTypes = {
  setUserInformation: PropTypes.func.isRequired,
};
