export default function ResultItem({
  result,
  handleClick,
  isSelected = false,
}) {
  return (
    <button
      onClick={handleClick}
      aria-label="Result item"
      disabled={isSelected}
      className={`flex flex-col space-y-4 w-full border rounded-lg py-2 px-4 cursor-pointer disabled:cursor-not-allowed disabled: dark:bg-secondary-dark bg-secondary-light shadow-md enabled:hover:shadow-blue-500 dark:enabled:hover:shadow-blue-600 transition-shadow duration-200 disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark`}>
      <h5 className="text-secondary-light dark:text-secondary-dark text-lg font-semibold text-left">
        {result.title}
      </h5>
      <p className="text-tertiary-light dark:text-tertiary-dark">
        {result.identifier}
      </p>
      <p className="text-tertiary-light dark:text-tertiary-dark">
        {result.length}
      </p>
    </button>
  );
}
