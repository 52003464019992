import PropTypes from "prop-types";

import { useState, useContext } from "react";

import DesktopNavBar from "../navbar/DesktopNavBar";
import MobileNavBar from "../navbar/MobileNavBar";
import MobileNavBarMenu from "../navbar/MobileNavBarMenu";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function NavBar({ clearUser }) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;

  return (
    <>
      <header className="bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-30 dark:border-gray-800 dark:bg-secondary-dark dark:text-white border-dark/50 dark:border-light/50 bg-secondary-light relative w-full border-b-2">
        <MobileNavBar
          isDark={isDark}
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={setIsOpenMenu}
        />
        <DesktopNavBar isDark={isDark} clearUser={clearUser} />
      </header>
      <MobileNavBarMenu
        clearUser={clearUser}
        isDark={isDark}
        isOpenMenu={isOpenMenu}
        setIsOpenMenu={setIsOpenMenu}
      />
    </>
  );
}

NavBar.propTypes = {
  clearUser: PropTypes.func.isRequired,
};
