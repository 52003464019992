import { useState } from "react";

import DomainSearch from "../module1/DomainSearch";
import FastaSearch from "../module1/FastaSearch";
import GeneSearch from "../module1/GeneSearch";
import NoSimilarity from "../module1/NoSimilarity";
import ProteinAlignment from "../module1/ProteinAlignment";
import Note from "../ui/Note";
import PageHeader from "../ui/PageHeader";
import useValidateProject from "../../hooks/useValidateProject";
import ContentLayout from "../../layouts/ContentLayout";
import SequenceTable from "../module1/ui/SequenceTable";
import ModuleSelectorButton from "../project_details/ui/ModuleSelectorButton";

// TODO: agregar tooltips de pregunta a los títulos de cada sección para que el usuario sepa qué es lo que tiene que hacer en cada paso

export default function Module1() {
  const { id: projectId, loadingInformation } = useValidateProject();

  const [geneResults, setGeneResults] = useState({
    clikedIdentifier: "",
    results: [],
  });

  const [alignmentResults, setAlignmentResults] = useState({
    clikedIdentifier: "",
    results: [],
  });

  const [domainSearchId, setDomainSearchId] = useState({
    value: "",
    error: "",
  });

  const [domainFasta, setDomainFasta] = useState({
    value: "",
    error: "",
  });

  const [cutSequence, setCutSequence] = useState("");
  const [proteinAlignmentResults, setProteinAlignmentResults] = useState([]);

  return (
    <>
      {!loadingInformation && (
        <PageHeader to={`/projects?project=${projectId}`} title="Module 1">
          <ModuleSelectorButton projectId={projectId} moduleId={1} />
        </PageHeader>
      )}

      <ContentLayout>
        <Note>
          The module conducts a comprehensive search, utilizing techniques such
          as BLASTX and protein domain analysis to identify if the candidate
          microorganism meet the specified criteria.
        </Note>

        <GeneSearch handleGeneResultsChange={setGeneResults} />

        <FastaSearch
          geneResults={geneResults}
          handleGeneResultsChange={setGeneResults}
          handleAlignmentResultsChange={setAlignmentResults}
          // Sequence alignment is nested in FastaSearch component
        />

        {alignmentResults.results[0] === "No significant similarity found." ? (
          <NoSimilarity />
        ) : (
          alignmentResults.results.length > 0 && (
            <SequenceTable
              results={alignmentResults.results}
              canSelect={true}
              handleDomainIdChange={setDomainSearchId}
            />
          )
        )}

        {domainSearchId.value && (
          <DomainSearch
            domainFasta={domainFasta}
            setCutSequence={setCutSequence}
            setDomainFasta={setDomainFasta}
            domainSearchId={domainSearchId}
          />
        )}

        {cutSequence && (
          <ProteinAlignment
            cutSequence={cutSequence}
            setProteinAlignmentResults={setProteinAlignmentResults}
          />
        )}

        {proteinAlignmentResults[0] === "No significant similarity found." ? (
          <NoSimilarity />
        ) : (
          proteinAlignmentResults.length > 0 && (
            <SequenceTable
              results={proteinAlignmentResults}
              canSelect={false}
              handleDomainIdChange={() => {}}
            />
          )
        )}
      </ContentLayout>
    </>
  );
}
