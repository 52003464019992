import { useContext } from "react";

import { GlobalContext } from "../../contexts/GlobalContext";
import { getImageUrl } from "../../utils/helpers";
import { navLinkClasses } from "../../utils/navbar/helpers";

export default function SettingsMenuButton({
  openMenu,
  setOpenMenu,
  setLogoutDisplayOptions,
  setThemeDisplayOptions,
}) {
  const { state } = useContext(GlobalContext);
  const isDark = state.darkMode;
  return (
    <button
      onClick={() => {
        setOpenMenu(!openMenu);
        setLogoutDisplayOptions(false);
        setThemeDisplayOptions(false);
      }}
      className={`${navLinkClasses(
        openMenu
      )} relative !cursor-pointer !rounded-md !p-2 `}>
      <img
        src={getImageUrl("settings.svg", isDark || openMenu)}
        alt="Settings button to open the settings menu"
        className="h-8 w-8"
      />
    </button>
  );
}
