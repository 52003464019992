export default function Input({
  error,
  identifier,
  placeholder,
  value,
  setValue,
  setShowOptions,
  readOnly = false,
  disabled = false,
}) {
  return (
    <input
      type="text"
      readOnly={readOnly}
      disabled={disabled}
      name={identifier}
      id={identifier}
      className={`rounded-l-lg w-full p-2 bg-secondary-light dark:bg-secondary-dark text-primary-light dark:text-primary-dark border outline-none  placeholder-gray-500 dark:placeholder-gray-400 focus:shadow-light dark:focus:shadow-dark focus:shadow-md duration-200 disabled:cursor-not-allowed disabled:bg-tertiary-light dark:disabled:bg-tertiary-dark ${
        error
          ? "border-red-500"
          : "border-light dark:border-dark dark:focus:!border-blue-400 focus:!border-blue-500"
      }`}
      placeholder={placeholder}
      value={value}
      onChange={({ target }) => setValue({ value: target.value, error: "" })}
      onFocus={() => setShowOptions(true)}
      onBlur={() => setShowOptions(false)}
      autoComplete="off"
    />
  );
}
