import DownloadResultsButton from "./DownloadResultsButton";

export default function OrganismInformation({ results }) {
  return (
    <div className="flex flex-col space-y-2 p-3 bg-secondary-light dark:bg-secondary-dark shadow-sm rounded-lg border border-light dark:border-dark">
      <h4 className="text-xl text-primary-light dark:text-primary-dark">
        Organism name:{" "}
        <span className="text-secondary-light dark:text-secondary-dark font-bold">
          {results.organism.recommended_name}
        </span>
      </h4>
      <p className="text-lg text-primary-light dark:text-primary-dark">
        EC Number:{" "}
        <span className="text-secondary-light dark:text-secondary-dark font-bold">
          {results.organism.ec_number}
        </span>
      </p>
      <p className="text-lg text-primary-light dark:text-primary-dark">
        GPP Search ID:{" "}
        <span className="text-secondary-light dark:text-secondary-dark font-bold">
          {results.organism.id}
        </span>
      </p>
      <DownloadResultsButton results={results} />
    </div>
  );
}
