import PropTypes from "prop-types";

import { Link } from "react-router-dom";

export default function ModuleLink({
  module,
  projectId,
  setIsOpen,
  open = false,
}) {
  return (
    <Link
      onClick={() => setIsOpen(false)}
      className={`hover:bg-primary-btn-hover dark:hover:bg-primary-btn-hoverDark bg-primary-btn-light dark:bg-primary-btn-dark px-4 py-2 text-center text-white duration-200 rounded-lg ${
        open ? "visible opacity-100" : "opacity-0 invisible"
      } transition-all duration-300`}
      to={`?project=${projectId}&module=${module.id}`}>
      Module {module.id}: {module.name}
    </Link>
  );
}

ModuleLink.propTypes = {
  open: PropTypes.bool.isRequired,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setIsOpen: PropTypes.func.isRequired,
  module: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
  }).isRequired,
};
