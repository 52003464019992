import { useContext } from "react";

import { GlobalContext } from "../contexts/GlobalContext";
import useValidateModule from "../hooks/useValidateModule";
import MainLayout from "../layouts/MainLayout";
import Module1 from "../components/modules/Module1";
import Module2 from "../components/modules/Module2";
import Module3 from "../components/modules/Module3";
import Module4 from "../components/modules/Module4";
import Module5 from "../components/modules/Module5";
import ProjectDetails from "../components/project_details/ProjectDetails";
import ProjectSelector from "../components/projects/ProjectSelector";
import LoadingScreen from "../components/project_details/ui/LoadingScreen";

export default function Projects() {
  const { projectId, moduleId } = useValidateModule();
  const { state } = useContext(GlobalContext);
  const loadingInformation = state.loadingInformation;

  return (
    <MainLayout relative={loadingInformation}>
      {loadingInformation && <LoadingScreen />}
      {!projectId && (
        <ProjectSelector loadingInformation={loadingInformation} />
      )}
      {projectId && (
        <>
          {!moduleId && <ProjectDetails />}
          {moduleId === 1 && <Module1 />}
          {moduleId === 2 && <Module2 />}
          {moduleId === 3 && <Module3 />}
          {moduleId === 4 && <Module4 />}
          {moduleId === 5 && <Module5 />}
        </>
      )}
    </MainLayout>
  );
}
