export default function TableHead() {
  return (
    <thead>
      <tr className="dark:bg-secondary-dark bg-secondary-light">
        <th className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
          Alignment Hit ID
        </th>
        <th className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
          Alignment Length
        </th>
        <th className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
          Alignment Title
        </th>
        <th className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
          HSP Expect
        </th>
        <th className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
          Identity
        </th>
        <th className="border-light dark:border-dark text-secondary-light dark:text-secondary-dark px-1 py-2 border">
          Query Coverage
        </th>
      </tr>
    </thead>
  );
}
