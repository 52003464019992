export default function Home() {
  return (
    <main className="bg-gradient-to-tl from-blue-900 via-purple-800 to-purple-600 min-h-[64dvh] flex flex-col justify-center items-center">
      <header className="container mx-auto px-4 py-6 text-center text-white dark:!text-gray-300">
        <h1 className="text-4xl md:text-6xl font-extrabold mb-4">
          Welcome to the Future of Biotechnology
        </h1>
        <p className="text-lg md:text-xl max-w-2xl mx-auto">
          Unlock the power of bioinformatics effortlessly with our
          next-generation platform, designed to simplify research and maximize
          innovation.
        </p>
      </header>

      <section className="container mx-auto px-4 py-6 grid gap-8 grid-cols-1 md:grid-cols-3">
        <article className="bg-white text-black dark:!bg-gray-800 dark:!text-gray-300 rounded-lg p-6 shadow-lg">
          <h2 className="text-2xl font-bold mb-4">Simplified Bioinformatics</h2>
          <p>
            Our platform streamlines complex bioinformatic procedures, making
            gene exploration and microorganism analysis easier than ever.
          </p>
        </article>

        <article className="bg-white text-black dark:!bg-gray-800 dark:!text-gray-300 rounded-lg p-6 shadow-lg">
          <h2 className="text-2xl font-bold mb-4">Intuitive Interface</h2>
          <p>
            Navigate with confidence using our user-friendly interface. Perform
            advanced analyses with just a few clicks.
          </p>
        </article>

        <article className="bg-white text-black dark:!bg-gray-800 dark:!text-gray-300 rounded-lg p-6 shadow-lg">
          <h2 className="text-2xl font-bold mb-4">Cutting-Edge Technology</h2>
          <p>
            Stay at the forefront of research with our constantly updated tools,
            helping you make groundbreaking discoveries.
          </p>
        </article>
      </section>
    </main>
  );
}
