import PropTypes from "prop-types";

export default function MenuButtonsContainer({
  children,
  openMenu = false,
  isMobile = false,
}) {
  const openMenuClass = openMenu ? "flex" : "hidden";
  return (
    <div
      className={
        isMobile
          ? `flex`
          : `${openMenuClass} space-y-3 flex-col absolute top-[22px] right-14 dark:bg-secondary-dark bg-secondary-light shadow-lg dark:shadow-dark shadow-light p-3 rounded-lg border border-light dark:border-dark`
      }>
      {children}
    </div>
  );
}

MenuButtonsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  openMenu: PropTypes.bool,
  isMobile: PropTypes.bool,
};
