import { useEffect, useRef } from "react";

export const useScrollIntoView = () => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return ref;
};
