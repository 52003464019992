import { useState } from "react";

import toast from "react-hot-toast";

import { API_URL } from "../../../utils/api";
import { getUser } from "../../../utils/user";

export default function DownloadResultsButton({ results }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const user = getUser();
      const response = await fetch(`${API_URL}/brenda/download_brenda_csv`, {
        method: "POST",
        body: JSON.stringify(results),
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw response;

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "results.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error(`An unexpected error has occured. Try again later`);
    }
    setIsLoading(false);
  };

  return (
    <button
      className="bg-primary-btn-light dark:bg-primary-btn-dark text-white rounded-lg enabled:hover:bg-primary-btn-hover enabled:dark:hover:bg-primary-btn-hoverDark duration-200 w-full disabled:cursor-not-allowed disabled:bg-opacity-70 px-4 py-2 flex justify-center items-center"
      onClick={handleClick}
      disabled={isLoading}>
      {isLoading ? (
        <img
          src="https://storage.googleapis.com/gpp4-bucket/src/light-loader.svg"
          alt="loader"
          className="w-7 h-7 mx-auto"
        />
      ) : (
        <div className="flex items-center space-x-4">
          <img
            src="https://storage.googleapis.com/gpp4-bucket/src/light-download.svg"
            alt="Download results as CSV"
            className="h-7 w-7"
          />
          <span className="text-primary-dark font-bold">
            Download results as a CSV file
          </span>
        </div>
      )}
    </button>
  );
}
