import { useEffect, useState } from "react";

import axios from "axios";

import toast from "react-hot-toast";

import { API_URL } from "../utils/api";

export default function useGenbankProtein(id, setDomainFasta) {
  const [isLoading, setIsLoading] = useState(false);
  const [begin, setBegin] = useState({ value: "", error: "" });
  const [end, setEnd] = useState({ value: "", error: "" });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/genbank/get/{id}?genbank_id=${id}&db=protein`
        );
        if (response.status !== 200) throw response;
        const sequence = response.data.fasta_sequence
          .split("\n")
          .slice(1)
          .join("")
          .trim();
        setDomainFasta({
          value: sequence,
          error: "",
        });
      } catch (error) {
        toast.dismiss();
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error("An error occurred while searching for results.");
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id, setDomainFasta]);

  return { isLoading, begin, setBegin, end, setEnd };
}
