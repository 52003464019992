import PropTypes from "prop-types";

export default function ErrorMessage({ error }) {
  const isArray = Array.isArray(error);

  return (
    <>
      {isArray ? (
        <div className="flex flex-col space-y-1">
          {error.map((message) => (
            <p
              key={message}
              className={`text-sm text-red-500 ${
                error ? "opacity-100" : "opacity-0"
              } transition-opacity duration-500`}>
              {message}
            </p>
          ))}
        </div>
      ) : (
        <p
          className={`text-sm text-red-500 ${
            error ? "opacity-100" : "opacity-0"
          } transition-opacity duration-500`}>
          {error}
        </p>
      )}
    </>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
