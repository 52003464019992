import PropTypes from "prop-types";

export default function Spacer({ className }) {
  return (
    <div
      className={`border-dark/50 dark:border-light/50 border rounded-full ${className}`}></div>
  );
}

Spacer.propTypes = {
  className: PropTypes.string,
};
