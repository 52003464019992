import { useState } from "react";

import axios from "axios";

import toast from "react-hot-toast";

import { API_URL } from "../../utils/api";

import SequenceAlignment from "./SequenceAlignment";

import FastaDisplay from "./ui/FastaDisplay";
import ResultItem from "./ui/ResultItem";

export default function FastaSearch({
  geneResults,
  handleGeneResultsChange,
  handleAlignmentResultsChange,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [previousIdentifier, setPreviousIdentifier] = useState("");
  const [geneFasta, setGeneFasta] = useState({ value: "", error: "" });

  async function handleFastaSearch(clikedIdentifier) {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/genbank/get/{id}?genbank_id=${clikedIdentifier}&db=nr`
      );
      if (response.status !== 200) throw response;

      setGeneFasta({
        value: response.data.fasta_sequence,
        error: "",
      });
    } catch (error) {
      toast.dismiss();
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error("An error occurred while searching for results.");
    }
    setIsLoading(false);
  }

  return (
    <>
      <article className="space-y-2">
        <div className="flex justify-between">
          <h4 className="text-2xl font-semibold text-secondary-light dark:text-secondary-dark">
            Results
          </h4>
          {geneResults.clikedIdentifier && (
            <button
              onClick={() => {
                setPreviousIdentifier(geneResults.clikedIdentifier);
                handleGeneResultsChange({
                  ...geneResults,
                  clikedIdentifier: "",
                });
              }}
              className="hover:text-blue-600 flex space-x-4 text-blue-500 underline cursor-pointer">
              See all results
            </button>
          )}
          {previousIdentifier && (
            <button
              onClick={() => {
                setPreviousIdentifier("");
                handleGeneResultsChange({
                  ...geneResults,
                  clikedIdentifier: previousIdentifier,
                });
              }}
              className="hover:text-blue-600 flex space-x-4 text-blue-500 underline cursor-pointer">
              Collpase results
            </button>
          )}
        </div>
        {geneResults.results.length === 0 ? (
          <p className="px-2 py-6 text-center bg-secondary-light dark:bg-secondary-dark border border-light dark:border-dark text-secondary-light dark:text-secondary-dark rounded-lg cursor-default">
            Please enter your Gene and Organism of interest to display results
          </p>
        ) : (
          <section className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {!geneResults.clikedIdentifier &&
              geneResults.results.map((result) => (
                <ResultItem
                  key={result.identifier}
                  result={result}
                  isSelected={previousIdentifier === result.identifier}
                  handleClick={() => {
                    handleFastaSearch(result.identifier);
                    setPreviousIdentifier("");
                    handleGeneResultsChange({
                      ...geneResults,
                      clikedIdentifier: result.identifier,
                    });
                    setGeneFasta({ value: "", error: "" });
                  }}
                />
              ))}

            {geneResults.clikedIdentifier &&
              geneResults.results
                .filter(
                  (result) => result.identifier === geneResults.clikedIdentifier
                )
                .map((result) => (
                  <ResultItem
                    isSelected={
                      geneResults.clikedIdentifier === result.identifier
                    }
                    key={result.identifier}
                    result={result}
                    handleClick={() => {}}
                  />
                ))}
          </section>
        )}
      </article>
      {(geneFasta.value || isLoading) && (
        <SequenceAlignment
          geneFasta={geneFasta}
          handleFastaChange={setGeneFasta}
          handleAlignmentResultsChange={handleAlignmentResultsChange}>
          <FastaDisplay
            isLoading={isLoading}
            fastaSequence={geneFasta.value}
            fastaError={geneFasta.error}
            handleFastaChange={setGeneFasta}
          />
        </SequenceAlignment>
      )}
    </>
  );
}
