import PropTypes from "prop-types";

import { useState } from "react";

import Projects from "../projects/Projects";
import PageTitle from "../ui/PageTitle";
import useRetrieveProjects from "../../hooks/useRetrieveProjects";
import ContentLayout from "../../layouts/ContentLayout";

import CreateNewProjectButton from "./CreateNewProjectButton";
import CreateNewProjectForm from "./CreateNewProjectForm";

export default function ProjectSelector({ loadingInformation }) {
  const [isOpenAddNewProject, setIsOpenAddNewProject] = useState(false);
  const { projects, setProjects } = useRetrieveProjects();

  return (
    !loadingInformation && (
      <ContentLayout>
        <PageTitle>Projects</PageTitle>
        {projects?.length === 0 && (
          <>
            <h3 className="text-secondary-light dark:text-secondary-dark text-2xl font-bold text-center">
              You don't have any projects yet
            </h3>
            <article className="bg-secondary-light dark:bg-secondary-dark dark:border-dark border-light lg:w-1/2 relative flex flex-col items-center justify-center p-4 mx-auto space-y-6 text-center border rounded-lg shadow-md w-full">
              {isOpenAddNewProject ? (
                <CreateNewProjectForm
                  closeForm={() => setIsOpenAddNewProject(false)}
                  setProjects={setProjects}
                />
              ) : (
                <CreateNewProjectButton
                  openForm={() => setIsOpenAddNewProject(true)}
                />
              )}
            </article>
          </>
        )}
        {projects?.length > 0 && (
          <Projects
            setProjects={setProjects}
            projects={projects}
            handleClick={setIsOpenAddNewProject}
            isFormOpen={isOpenAddNewProject}
          />
        )}
      </ContentLayout>
    )
  );
}

ProjectSelector.propTypes = {
  loadingInformation: PropTypes.bool.isRequired,
};
