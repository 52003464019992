export default function AutocompleteOptions({
  isLoading,
  options,
  setValue,
  show,
}) {
  return (
    <div
      className={`w-full bg-secondary-light dark:bg-secondary-dark rounded-lg shadow-md ${
        !show && "absolute"
      }`}>
      {isLoading && (
        <div className="text-secondary-light dark:text-secondary-dark p-2 text-center cursor-default">
          Loading...
        </div>
      )}
      {options.length > 0 &&
        !isLoading &&
        options.map((option) => (
          <button
            onMouseDown={() =>
              setValue({ value: `${option[2]} - ${option[0]}`, error: "" })
            }
            key={option[2]}
            className="hover:bg-tertiary-light dark:hover:bg-tertiary-dark text-tertiary-light dark:text-tertiary-dark w-full p-2 text-left cursor-pointer">
            {`${option[2]} - ${option[0]}`}
          </button>
        ))}
      {options.length === 0 && !isLoading && (
        <div className="text-secondary-light dark:text-secondary-dark p-2 text-center cursor-default">
          No options available
        </div>
      )}
    </div>
  );
}
